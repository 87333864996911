import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterChip from '../../../shared-components/FilterChip';
import { ShoppingBagOutlined, TableBarOutlined } from '@mui/icons-material';

const OrderTypeFilterChips = ({ onFilterChange }) => {
    const [selectedChips, setSelectedChips] = useState({
        IN_TABLE: true,
        TAKE_OUT: true,
    });

    const handleChipClick = (chip) => {
        const updatedChips = {
            ...selectedChips,
            [chip]: !selectedChips[chip],
        };
        setSelectedChips(updatedChips);
        onFilterChange(updatedChips); // Notify parent of the filter change
    };

    const handleClearFilters = () => {
        const resetChips = {
            IN_TABLE: true,
            TAKE_OUT: true,
        };
        setSelectedChips(resetChips);
        onFilterChange(resetChips); // Notify parent of the filter reset
    };

    return (
        <Box display="flex" flexDirection="row" alignItems="center" overflow={"scroll"} gap={1} width="100%">
            <IconButton onClick={handleClearFilters}>
                <ClearIcon />
            </IconButton>
            <FilterChip
                label={<Typography sx={{ display: 'flex', alignItems: 'center' }}><TableBarOutlined />&nbsp;À table</Typography>}
                selected={selectedChips.IN_TABLE}
                onClick={() => handleChipClick('IN_TABLE')}
            />
            <FilterChip
                label={<Typography sx={{ display: 'flex', alignItems: 'center' }}><ShoppingBagOutlined />&nbsp;À emporter</Typography>}
                selected={selectedChips.TAKE_OUT}
                onClick={() => handleChipClick('TAKE_OUT')}
            />
        </Box>
    );
};

export default OrderTypeFilterChips;
