import { useContext, useEffect, useState, useCallback } from 'react';
import { OrdersContext } from '../context/OrdersContext';
import { AuthContext } from '../context/AuthContext';
import useApi from '../api';

const useOrder = () => {
    const { eateryId } = useContext(AuthContext);
    const { orders, setOrders, loading } = useContext(OrdersContext);
    const { createOrderApi, deleteOrderApi, updateOrderApi } = useApi();

    const getOrderById = useCallback((orderId) => {
        return orders.find(order => order.id === orderId);
    }, [orders]);

    const createOrder = async (orderData) => {
        try {
            const newOrder = await createOrderApi(orderData);
            setOrders(prevOrders => [...prevOrders, newOrder]);
            return newOrder;
        } catch (error) {
            console.error('Error creating order:', error);
            throw error;
        }
    };

    const deleteOrder = async (orderId) => {
        try {
            await deleteOrderApi(orderId);
            setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
        } catch (error) {
            console.error('Error deleting order:', error);
            throw error;
        }
    };

    const updateOrder = async (orderId, updatedOrderData) => {
        try {
            const updatedOrder = await updateOrderApi(orderId, updatedOrderData);
            setOrders(prevOrders => prevOrders.map(order => (order.id === orderId ? updatedOrder : order)));
            return updatedOrder;
        } catch (error) {
            console.error('Error updating order:', error);
            throw error;
        }
    };

    return {
        orders,
        loading,
        getOrderById,
        createOrder,
        deleteOrder,
        updateOrder,
    };
};

export default useOrder;
