import React from 'react';
import { Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const FilterChip = ({ label, backgroundColor, textColor, borderColor, selected, onClick }) => {
  return (
    <Chip
      label={label}
      onClick={onClick}
      icon={selected ? <CheckIcon /> : null}
      sx={{
        backgroundColor: backgroundColor,
        color: textColor,
        border: borderColor ? `1px solid ${borderColor}` : 'none',
        borderRadius: '8px',
        padding: '0px',
        '& .MuiChip-label': {
          gap: '8px',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.1px',
        },
        '& .MuiChip-icon': {
          marginLeft: '8px',
          marginRight: '-2px',
          color: textColor,
          width: '18px',
          height: '18px',
        },
      }}
    />
  );
};

export default FilterChip;
