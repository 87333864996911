import { Box, Button, Container, Drawer, Slider, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import InsertPhotoIcon from '@mui/icons-material/InsertPhotoOutlined';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import '../../../../css/loader.css';
import getCroppedImg from '../../utils/cropImage';

export default function ImageUploader({ originalImage, onImageCropped, onDelete }) {
    const [selectedImage, setSelectedImage] = useState(originalImage);
    const [editMode, setEditMode] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [deletePhotoDrawerOpen, setDeletePhotoDrawerOpen] = useState(false);
    const fileInput = useRef();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const placeholder = {
        original: {
            jpg: 'https://fknf.fra1.cdn.digitaloceanspaces.com/common/assets/placeholder.jpg',
            webp: 'https://fknf.fra1.cdn.digitaloceanspaces.com/common/assets/placeholder.webp',
        }
    };

    useEffect(() => {
        if (!originalImage) {
            setSelectedImage(placeholder);
        }
    }, [originalImage]);

    const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels);
        onImageCropped(croppedImage);
    }, [selectedImage, onImageCropped]);

    const toggleDeletePhotoDrawer = (newOpen) => () => {
        setDeletePhotoDrawerOpen(newOpen);
    };

    const handleImageClick = () => {
        setShowOverlay(true);
    };

    const handleOverlayClick = (event) => {
        if (event.target.tagName !== 'BUTTON') {
            setShowOverlay(false);
        }
    };

    const handleUploadImageClick = event => {
        var file = event.target.files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setEditMode(true);
            setSelectedImage(reader.result);
        }.bind(this);
    };

    useEffect(() => {
        let timer;
        if (showOverlay) {
            timer = setTimeout(() => {
                setShowOverlay(false);
            }, 3000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [showOverlay]);

    if (!selectedImage) {
        return <div className="loader"></div>
    }

    if (!editMode) {
        return (
            <Box
                sx={{
                    aspectRatio: '1/1',
                    position: 'relative',
                    mb: -2,
                    overflow: 'hidden',
                }}
            >
                <picture>
                    {selectedImage?.original?.webp && <source srcSet={selectedImage.original.webp} type="image/webp" />}
                    {selectedImage?.original?.avif && <source srcSet={selectedImage.original.avif} type="image/avif" />}
                    {selectedImage?.original?.jpg && (
                        <Box
                            component="img"
                            src={selectedImage}
                            alt="Food Item"
                            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
                            onClick={handleImageClick}
                        />
                    )}
                </picture>
                {/* Overlay with two buttons */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        opacity: showOverlay ? 1 : 0,
                        visibility: showOverlay ? 'visible' : 'hidden',
                        transition: 'opacity 0.5s ease, visibility 0.5s ease', // Added transition for visibility
                    }}
                    onClick={handleOverlayClick}
                >
                    {/* Two buttons in the overlay */}
                    <Button variant='contained' sx={{ minWidth: '80%', mb: 3 }} startIcon={<InsertPhotoIcon />} onClick={() => fileInput.current.click()}>
                        Choisir une nouvelle photo
                    </Button>
                    <input
                        ref={fileInput}
                        accept="image/*"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadImageClick}
                    />
                    {selectedImage?.original?.jpg !== placeholder.original.jpg &&
                        <Button onClick={toggleDeletePhotoDrawer(true)} variant='contained' sx={{ minWidth: '80%', mb: 3 }} color='error' startIcon={<DeleteIcon />}>
                            Supprimer cette photo
                        </Button>
                    }
                </Box>

                {/* Delete photo drawer */}
                <Drawer anchor='bottom' open={deletePhotoDrawerOpen} onClose={toggleDeletePhotoDrawer(false)}>
                    <Box sx={{ width: '100%', p: 2 }} role="presentation" onClick={toggleDeletePhotoDrawer(false)}>
                        <Typography variant="body">Êtes-vous sûr(e) de vouloir supprimer cette photo?</Typography>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={toggleDeletePhotoDrawer(false)}
                            sx={{ mt: 2, ml: 2 }}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={async () => {
                                await onDelete();
                                setDeletePhotoDrawerOpen(false);
                                setSelectedImage(placeholder);
                            }}
                            sx={{ mt: 2 }}
                        >
                            Supprimer
                        </Button>
                    </Box>
                </Drawer>
            </Box>
        );
    }
    else { // Edit mode
        return (
            <Box
                sx={{
                    aspectRatio: '1/1',
                    position: 'relative',
                    mb: -2,
                    overflow: 'hidden',
                }}
            >
                {/* <Box
                    component="img"
                    src={selectedImage}
                    alt="Food Item"
                    sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
                    onClick={handleImageClick}
                /> */}
                <Cropper
                    image={selectedImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit='cover'
                //style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                />
                {/* <div
                    // className={classes.controls}
                >
                    <div
                        // className={classes.sliderContainer}
                    >
                        <Typography
                            variant="overline"
                        //classes={{ root: classes.sliderLabel }}
                        >
                            Zoom
                        </Typography>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            //classes={{ root: classes.slider }}
                            onChange={(e, zoom) => setZoom(zoom)}
                        />
                    </div>
                </div> */}
            </Box>
        );
    }

}
