import React from 'react';
import { Box, Typography, Switch, Avatar } from '@mui/material';
import theme from '../../../../theme';

const SwitchField = ({ name, label, emoji, checked, handleChange }) => (
    <Box sx={{ mb: 0.3, display: 'flex', gap: 2, alignItems: 'center', paddingY: 1 }}>
        <Avatar sx={{ backgroundColor: theme.palette.primary.dark }}>
            {emoji}
        </Avatar>
        <Typography variant="body" flexGrow={1}>{label}</Typography>
        <Switch
            name={name}
            checked={checked}
            onChange={handleChange}
        />
    </Box>
);

export default SwitchField;
