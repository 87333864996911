import React from 'react';
import { Box, TextField, Button, IconButton, Typography, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useFormikContext, getIn } from 'formik';
import theme from '../../../../theme';

const OtherSizesField = ({ currency }) => {
    const { values, touched, errors, handleChange, handleBlur, setFieldValue } = useFormikContext();

    const handleDeleteSize = (index) => {
        const updatedSizes = values.otherSizes.filter((_, i) => i !== index);
        setFieldValue('otherSizes', updatedSizes);
    };

    const handleAddSize = () => {
        setFieldValue('otherSizes', [...(values.otherSizes || []), { name: '', price: '' }]);
    };

    const handleSizeNameChange = (index, newName) => {
        const updatedSizes = [...values.otherSizes];
        updatedSizes[index].name = newName;
        setFieldValue('otherSizes', updatedSizes);
    };

    const handlePriceChange = (index, newPrice) => {
        const updatedSizes = [...values.otherSizes];
        updatedSizes[index].price = newPrice;
        setFieldValue('otherSizes', updatedSizes);
    };

    return (
        <Box sx={{ mb: 2, width: '100%', border: '1px solid', borderColor: theme.palette.outline.main, borderRadius: '8px', background: theme.palette.surface.container }} padding={2}>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <InputLabel htmlFor="other-sizes-form" sx={{ transform: 'scale(0.75)' }}>
                    Autres tailles
                </InputLabel>
            </Box>
            {values.otherSizes && values.otherSizes.map((size, index) => (
                <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid', borderColor: theme.palette.outline.main, borderRadius: '8px', background: theme.palette.container.primary.lowest, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TextField
                            name={`otherSizes[${index}].name`}
                            label="Nom de la taille (ex: Petit, Grand)"
                            variant='standard'
                            fullWidth
                            sx={{ mb: 2 }}
                            value={size.name}
                            onChange={(e) => handleSizeNameChange(index, e.target.value)}
                            onBlur={handleBlur}
                            error={getIn(touched, `otherSizes[${index}].name`) && Boolean(getIn(errors, `otherSizes[${index}].name`))}
                            helperText={getIn(touched, `otherSizes[${index}].name`) && getIn(errors, `otherSizes[${index}].name`)}
                        />
                        <IconButton onClick={() => handleDeleteSize(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <TextField
                            name={`otherSizes[${index}].price`}
                            label="Prix"
                            variant='standard'
                            sx={{ width: '70%', mr: 1 }}
                            value={size.price}
                            onChange={(e) => handlePriceChange(index, e.target.value)}
                            onBlur={handleBlur}
                            error={getIn(touched, `otherSizes[${index}].price`) && Boolean(getIn(errors, `otherSizes[${index}].price`))}
                            helperText={getIn(touched, `otherSizes[${index}].price`) && getIn(errors, `otherSizes[${index}].price`)}
                            InputProps={{ endAdornment: <Typography variant="subtitle2">{currency}</Typography> }}
                        />
                    </Box>
                </Box>
            ))}
            {errors.otherSizes && typeof errors.otherSizes === 'string' && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {errors.otherSizes}
                </Typography>
            )}
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddSize}
                sx={{ mt: 2 }}
            >
                Ajouter une autre taille
            </Button>
        </Box>
    );
};

export default OtherSizesField;
