import React from "react";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useTheme } from "@emotion/react";

const AddCategoryButton = ({ onClick }) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                mb: 2,
                backgroundColor: theme.palette.primary.light,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            <CardContent>
                <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                    <Avatar sx={{
                        height: '2rem',
                        width: '2rem',
                        aspectRatio: 1 / 1,
                        padding: '4px',
                        background: theme.palette.primary.dark,
                        borderColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                        border: '1px solid'
                    }}>
                        <CIcon icon={cilPlus} size="sm" />
                    </Avatar>
                    <Typography variant="body" sx={{ fontWeight: 'bold', mt: 2 }}>
                        Ajouter une nouvelle catégorie
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AddCategoryButton;
