import { Check } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Container, Drawer, Grid, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import { CategoryContext } from '../../context/CategoryContext';
import '../../css/loader.css';
import BackButton from '../../shared-components/BackButton';
import LongButton from '../../shared-components/LongButton';
import theme from '../../theme';
import CategoryField from './components/fields/CategoryField';
import DescriptionField from './components/fields/DescriptionField';
import ExtrasField from './components/fields/ExtrasField';
import ImageUploader from './components/fields/ImageUploader';
import PriceField from './components/fields/PriceField';
import RequiredChoicesField from './components/fields/RequiredChoicesField';
import SwitchField from './components/fields/SwitchField';
import TitleField from './components/fields/TitleField';
import FailureSnackbar from './components/snackbars/FailureSnackbar';
import SuccessSnackbar from './components/snackbars/SuccessSnackbar';
import OtherSizesField from './components/fields/OtherSizesField';
import { Topbar } from '../../shared-components/Topbar';

const FoodItemBuilder = ({ update }) => {
    const navigate = useNavigate();
    const { eateryId } = useContext(AuthContext);
    const { categories } = useContext(CategoryContext);
    const { data, currency, loading, handleCreateOrUpdateFoodItem, handleDeleteFoodItem, handleDeleteFoodItemPhoto } = useContext(AppContext);
    const { findCategoryById, setSelectedCategory } = useContext(CategoryContext);
    const [selectedImage, setSelectedImage] = useState(null);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const foodItemData = location.state || {};
    const [snackbars, setSnackbars] = useState({
        createSuccess: false,
        createFailure: false,
        updateSuccess: false,
        updateFailure: false,
        deleteSuccess: false,
        deleteFailure: false,
        updatePhotoSuccess: false,
        updatePhotoFailure: false,
        deletePhotoSuccess: false,
        deletePhotoFailure: false
    });

    const setCategory = (categoryId) => {
        setSelectedCategory(findCategoryById(categoryId));
    }

    const initialValues = {
        id: foodItemData.id,
        eateryId: eateryId,
        name: foodItemData.name || '',
        description: foodItemData.description || '',
        order: foodItemData.order,
        price: foodItemData.price || '',
        newItem: foodItemData.newItem || false,
        popular: foodItemData.popular || false,
        outOfStock: foodItemData.outOfStock || false,
        category: foodItemData.category,
        photo: foodItemData.photo,
        otherSizes: foodItemData.otherSizes ? Object.entries(JSON.parse(foodItemData.otherSizes)).map(([name, price]) => ({ name, price })) : [],
        requiredChoices: foodItemData.requiredChoices ? Object.entries(JSON.parse(foodItemData.requiredChoices)).map(([choiceName, options]) => ({
            name: choiceName,
            options: Object.entries(options).map(([optionName, price]) => ({
                choice: optionName,
                price: price
            }))
        })) : [],
        extras: (foodItemData.extras && Object.entries(JSON.parse(foodItemData.extras)).map(([name, price]) => ({ name, price }))) || [{ name: '', price: '' }],
    };

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        values.order = foodItemData.order;

        try {
            // Convert otherSizes, requiredChoices, and extras back to the necessary structure
            const formattedValues = {
                ...values,
                otherSizes: values.otherSizes.length > 0
                    ? values.otherSizes.reduce((acc, sizeObj) => {
                        if (sizeObj.name.trim() !== '') {
                            acc[sizeObj.name] = parseFloat(sizeObj.price);
                        }
                        return acc;
                    }, {})
                    : null,
                requiredChoices: values.requiredChoices.reduce((acc, curr) => {
                    acc[curr.name] = curr.options.reduce((optAcc, opt) => {
                        optAcc[opt.choice] = parseFloat(opt.price);
                        return optAcc;
                    }, {});
                    return acc;
                }, {}),
                extras: values.extras.reduce((acc, curr) => {
                    acc[curr.name] = parseFloat(curr.price);
                    return acc;
                }, {}),
            };

            await handleCreateOrUpdateFoodItem(formattedValues, update, foodItemData.id, selectedImage);
            setSnackbars((prev) => ({ ...prev, [update ? 'updateSuccess' : 'createSuccess']: true }));
            setCategory(values.category);
        } catch (error) {
            setSnackbars((prev) => ({ ...prev, [update ? 'updateFailure' : 'createFailure']: true }));
        } finally {
            setSubmitting(false);
        }
    };


    const handleDeletePhoto = async () => {
        try {
            await handleDeleteFoodItemPhoto(foodItemData);
            setSnackbars((prev) => ({ ...prev, deletePhotoSuccess: true }));
        } catch (error) {
            setSnackbars((prev) => ({ ...prev, deletePhotoFailure: true }));
        }
    }

    const handleDelete = async () => {
        try {
            await handleDeleteFoodItem(foodItemData.id);
            setSnackbars((prev) => ({ ...prev, deleteSuccess: true }));
            setTimeout(() => {
                navigate(-1);
            }, 1500);
        } catch (error) {
            setSnackbars((prev) => ({ ...prev, deleteFailure: true }));
        }
    };

    if (loading || !data || !currency) {
        return <div className="loader"></div>
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Ce champ est obligatoire'),
        price: Yup.number().required('Obligatoire').positive('Doit être un nombre positif'),
        otherSizes: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Le nom de la taille est requis.'),
                price: Yup.number().required('Le prix est requis.').positive('Le prix doit être un nombre positif.')
            })
        ).test(
            'at-least-one-size',
            'Au moins une taille valide est requise.',
            (value) => !value || value.length === 0 || value.some(size => size.name?.trim() !== '' && !isNaN(size.price) && size.price > 0)
        ),
        requiredChoices: Yup.array().of(
            Yup.object({
                name: Yup.string().required('Ce champ est obligatoire'),
                options: Yup.array().of(
                    Yup.object({
                        choice: Yup.string().required('Ce champ est obligatoire'),
                        price: Yup.number().required('Ce champ est obligatoire').min(0, 'Doit être un nombre positif ou zéro'),
                    })
                ),
            })
        ),
        extras: Yup.array().of(
            Yup.object({
                name: Yup.string().required('Ce champ est obligatoire'),
                price: Yup.number().required('Ce champ est obligatoire').positive('Doit être un nombre positif'),
            })
        )
    });


    return (
        <Container maxWidth="sm" disableGutters style={{ paddingX: 0, paddingY: '16px', fontFamily: '"Inter", sans-serif' }}>
            <Topbar
                name={update ? 'Mettre à jour un produit' : 'Créer'}
                subtitle={update ? 'Modifier un produit existant' : 'Ajouter un nouveau produit'}
                showBackButton
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    {/* <BackButton onClick={() => setCategory(initialValues.category)} sx={{
                        backgroundColor: theme.palette.background.paper,
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.25)'
                        },
                    }} /> */}
                    <Card sx={{ m: 0 }}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <CardContent sx={{ pb: '0 !important', p: 0, display: 'flex', flexDirection: 'column' }}>
                                            {/* Image Section */}
                                            <ImageUploader originalImage={foodItemData.photo} onImageCropped={(newPhoto) => setSelectedImage(newPhoto)} onDelete={handleDeletePhoto} />
                                            <Paper sx={{
                                                flexGrow: 1,
                                                position: 'relative',
                                                px: 3,
                                                py: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 3,
                                                background: theme.palette.background.default,
                                                pt: 4,
                                                borderTop: '1px solid ' + theme.palette.outline.main,
                                            }}>
                                                {/* Title Section */}
                                                <TitleField
                                                    values={values}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    errors={errors}
                                                    toggleDrawer={toggleDrawer}
                                                    update={update}
                                                />
                                                {/* Category Section */}
                                                <CategoryField
                                                    categories={categories}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                                {/* Description Section */}
                                                <DescriptionField
                                                    values={values}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                                {/* Price Section */}
                                                <PriceField
                                                    currency={currency}
                                                />
                                                {/* Other sizes Section */}
                                                <OtherSizesField
                                                    currency={currency}
                                                />
                                                {/* Required choices Section */}
                                                <RequiredChoicesField
                                                    values={values}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                />
                                                {/* Extras Section */}
                                                <ExtrasField
                                                    values={values}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                />
                                                {/* Switches Section */}
                                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', px: 4 }}>
                                                    <SwitchField
                                                        name="newItem"
                                                        label="Nouveau"
                                                        emoji="🔥"
                                                        checked={values.newItem}
                                                        handleChange={handleChange}
                                                    />
                                                    <SwitchField
                                                        name="popular"
                                                        label="Populaire"
                                                        emoji="⭐"
                                                        checked={values.popular}
                                                        handleChange={handleChange}
                                                    />
                                                    <SwitchField
                                                        name="outOfStock"
                                                        label="Indisponible"
                                                        emoji="❌"
                                                        checked={values.outOfStock}
                                                        handleChange={handleChange}
                                                    />
                                                </Box>
                                                {/* Button Section */}
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, width: '100%' }}>
                                                    <LongButton
                                                        variant="outlined"
                                                        color="primary"
                                                        icon={Check}
                                                        label={update ? 'Mettre à jour' : 'Créer'}
                                                        loading={isSubmitting}
                                                        disabled={isSubmitting}
                                                        onClick={handleSubmit}
                                                    >
                                                    </LongButton>
                                                </Box>
                                            </Paper>
                                        </CardContent>
                                    </form>
                                )
                            }}
                        </Formik>
                        <Drawer anchor='bottom' open={open} onClose={toggleDrawer(false)}>
                            <Box sx={{ width: '90%', p: 2 }} role="presentation">
                                <Typography variant="body">Êtes-vous sûr(e) de vouloir supprimer cet article?</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', p: 1 }}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={toggleDrawer(false)}
                                        sx={{ mt: 2, ml: 2 }}
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleDelete()}
                                        sx={{ mt: 2 }}
                                    >
                                        Supprimer
                                    </Button>
                                </Box>
                            </Box>
                        </Drawer>
                    </Card>
                </Grid>
            </Grid>
            <SuccessSnackbar open={snackbars.createSuccess} onClose={() => setSnackbars((prev) => ({ ...prev, createSuccess: false }))} message="Nouvel article créé avec succès!" />
            <SuccessSnackbar open={snackbars.updateSuccess} onClose={() => setSnackbars((prev) => ({ ...prev, updateSuccess: false }))} message="Article mis à jour avec succès!" />
            <SuccessSnackbar open={snackbars.deleteSuccess} onClose={() => setSnackbars((prev) => ({ ...prev, deleteSuccess: false }))} message="Suppression effectuée avec succès!" />
            <SuccessSnackbar open={snackbars.updatePhotoSuccess} onClose={() => setSnackbars((prev) => ({ ...prev, updatePhotoSuccess: false }))} message="Photo mise à jour avec succès!" />
            <SuccessSnackbar open={snackbars.deletePhotoSuccess} onClose={() => setSnackbars((prev) => ({ ...prev, deletePhotoSuccess: false }))} message="Photo supprimée!" />
            <FailureSnackbar open={snackbars.createFailure} onClose={() => setSnackbars((prev) => ({ ...prev, createFailure: false }))} message="Erreur lors de la création de l'article" />
            <FailureSnackbar open={snackbars.updateFailure} onClose={() => setSnackbars((prev) => ({ ...prev, updateFailure: false }))} message="Erreur lors de la mise à jour de l'article" />
            <FailureSnackbar open={snackbars.deleteFailure} onClose={() => setSnackbars((prev) => ({ ...prev, deleteFailure: false }))} message="Erreur lors de la suppression de l'article" />
            <FailureSnackbar open={snackbars.updatePhotoFailure} onClose={() => setSnackbars((prev) => ({ ...prev, updatePhotoFailure: false }))} message="Erreur lors de la mise à jour de la photo" />
            <FailureSnackbar open={snackbars.deletePhotoFailure} onClose={() => setSnackbars((prev) => ({ ...prev, deletePhotoFailure: false }))} message="Erreur lors de la suppression de la photo" />
        </Container>
    );
};

export default FoodItemBuilder;
