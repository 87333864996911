import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import theme from '../../../theme';

const AddTableButton = ({ onAddTable }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTableNumber, setNewTableNumber] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleBlur = () => {
    if (newTableNumber.trim() !== '') {
      onAddTable(parseInt(newTableNumber, 10));
    }
    setIsEditing(false);
    setNewTableNumber('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (newTableNumber.trim() !== '') {
        onAddTable(parseInt(newTableNumber, 10));
      }
      setIsEditing(false);
      setNewTableNumber('');
    }
  };

  return (
    <Box
      onClick={() => setIsEditing(true)}
      sx={{
        width: '81px',
        height: '81px',
        aspectRatio: '1/1',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #9747FF',
        borderRadius: '16px',
        cursor: 'pointer',
      }}
    >
      {isEditing ? (
        <TextField
          inputRef={inputRef}
          value={newTableNumber}
          onChange={(e) => setNewTableNumber(e.target.value)}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              textAlign: 'center',
              fontSize: '24px',
            },
          }}
        />
      ) : (
        <IconButton>
          <AddIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default AddTableButton;
