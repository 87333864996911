import { useContext, useEffect, useState, useCallback } from 'react';
import { AppContext } from '../context/AppContext';
import useApi from '../api';
import { AuthContext } from '../context/AuthContext';

const useRatings = () => {
    const {
        getRatings
    } = useApi();
    const { eateryId } = useContext(AuthContext);
    const [ratings, setRatings] = useState(null);

    useEffect(() => {
        const get = async () => {
            const response = await getRatings(eateryId);
            setRatings(response);
        }

        if (!ratings) {
            get();
        }

    }, [ratings, eateryId]);

    return { ratings };
};

export default useRatings;
