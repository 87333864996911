import React, { useContext, useState } from "react";
import { AppBar, Box, Button, Checkbox, Container, FormControlLabel, FormGroup, TextField, Toolbar, Typography } from "@mui/material";
import BackButton from "../../shared-components/BackButton";
import forknifeLogo from '../../assets/images/forknife.png';
import theme from '../../assets/json/defaulttheme.json';
import { AppContext } from "../../context/AppContext";
import '../../css/loader.css';

const Customize = () => {
    const { loading } = useContext(AppContext);
    const placeholder = theme.foodItemBuilderPlaceholder?.$binary?.base64 || '';

    if (loading) {
        return <div className="loader"></div>
    };

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 0 }}>
            <Box
                alignItems="baseline"
                p={2}
                sx={{
                    margin: 0,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: "wrap",
                    borderTopLeftRadius: "inherit",
                    borderTopRightRadius: "inherit",
                    bgcolor: (theme) =>
                        theme.palette.mode === "dark" ? "#2f3c50" : "#fff"
                }}
            >
                <AppBar position="static" sx={{ bgcolor: 'background.paper', boxShadow: 'none', mb: 3 }}>
                    <Toolbar>
                        <Box marginRight={2}>
                            <BackButton to='/' position="static" />
                        </Box>
                        <Box>
                            <Typography variant="h6" color={"MenuText"}>
                                <b>Personnaliser mon menu</b>
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
                {/* Modify logo */}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
                    <img src={forknifeLogo} />
                    <Button
                        size="small"
                        variant="outlined"
                        sx={{
                            mt: 3,
                            mb: 3,
                            borderRadius: 5,
                            padding: "0.125rem 0.75rem",
                            borderColor: "#becddc",
                            fontSize: "0.75rem"
                        }}
                        onClick={() => onOpen()}
                    >
                        Modifier le logo
                    </Button>
                    <Box>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Ne pas afficher le logo en haut du menu (Afficher la bannière seulement)" />
                        </FormGroup>
                    </Box>
                </Box>
                {/* Restaurant name */}
                <Box sx={{ mt: 3, justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" textAlign='center'>
                        Nom du restaurant
                    </Typography>
                    <Typography variant="subtitle2" textAlign='center' color="textSecondary">
                        Nom du restaurant
                    </Typography>
                </Box>
                <Box sx={{ mt: 3, width: '100%' }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Description
                    </Typography>
                    <TextField variant='standard' fullWidth multiline defaultValue="(Nom du restaurant) a le plaisir de vous accueillir au sein de son établissement">
                    </TextField>
                </Box>
                <Box sx={{ mt: 3, width: '100%' }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Bannière
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={`data:image/jpeg;base64,${placeholder}`}
                            alt="Food Item"
                            style={{ borderRadius: '20px', objectFit: 'cover', width: '100%', height: '100%' }}
                        />
                        <Button
                            size="small"
                            variant="outlined"
                            sx={{
                                mt: 2,
                                mb: 3,
                                borderRadius: 5,
                                padding: "0.125rem 0.75rem",
                                borderColor: "#becddc",
                                fontSize: "0.75rem"
                            }}
                            onClick={() => onOpen()}
                        >
                            Modifier la bannière
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container >
    );
};

export default Customize;