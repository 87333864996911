import React, { useContext, useState } from 'react';
import { Box, Container, CssBaseline, Stack, Typography, IconButton, Grid, Paper } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';  // Icon for opening the side panel
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { AppContext } from '../../context/AppContext';
import LongButton from '../../shared-components/LongButton';
import SidePanel from './components/SidePanel';  // Import the new SidePanel component
import "../../css/loader.css";
import MenuBox from './components/MenuBox';
import OrdersBox from './components/OrdersBox';
import TablesBox from './components/TablesBox';
import { OrdersProvider } from '../../context/OrdersContext';
import StatsBox from './components/StatsBox';

export default function Dashboard3() {
    const { user, logout } = useContext(AuthContext);
    const { data } = useContext(AppContext);
    const theme = useTheme();
    const [sidePanelOpen, setSidePanelOpen] = useState(false);

    const handleToggleSidePanel = () => {
        setSidePanelOpen((prev) => !prev);
    };

    if (!data) {
        return <div className="loader"></div>;
    }

    return (
        <Container
            maxWidth="md"
            display="flex"
            padding={2}
            sx={{
                height: '100%',
                display: 'flex',
                backgroundColor: theme.palette.background.default,
                flexDirection: "column",
                alignItems: "center",
            }}
        >

            {/* Side panel */}
            <SidePanel open={sidePanelOpen} onClose={handleToggleSidePanel} />

            <Stack spacing={1} sx={{ width: '100%', alignItems: 'center', flexGrow: 1 }}>
                {/* Title */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px 0px', width: '100%' }}>
                    <IconButton onClick={handleToggleSidePanel}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='header' sx={{ fontSize: 'clamp(24px, 5vw + 1rem, 48px)', lineHeight: '120%', letterSpacing: '-0.02em', color: theme.palette.text.primary, flexGrow: 1 }}>
                        {data.eatery.name}
                    </Typography>
                    <picture>
                        {/* Responsive logo */}
                        {data.eatery.theme.logo.webp && <source srcSet={data.eatery.theme.logo.webp} type="image/webp" />}
                        {data.eatery.theme.logo.avif && <source srcSet={data.eatery.theme.logo.avif} type="image/avif" />}
                        {data.eatery.theme.logo.jpg && <source srcSet={data.eatery.theme.logo.jpg} type="image/jpg" />}
                        <Box
                            component="img"
                            srcSet={`${data.eatery.theme.logo}`}
                            sx={{ width: '106px' }}
                        />
                    </picture>
                </Box>

                <Box sx={{ flexGrow: 1, padding: 5 }}>
                    {/* Header */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* TablesBox - Takes 2x1 */}
                            <Link to="/tables" style={{ width: '100%', textDecoration: 'none' }}>
                                <TablesBox />
                            </Link>
                        </Grid>

                        {user.role === 'ADMIN' ? (
                            <>
                                {/* MenuBox for Admin (1x1) */}
                                <Grid item xs={12} sm={6}>
                                    <Link to="/menu" style={{ color: 'inherit', textDecoration: 'none', width: '100%' }}>
                                        <MenuBox />
                                    </Link>
                                </Grid>

                                {/* OrdersBox for Admin (1x1) */}
                                <Grid item xs={12} sm={6}>
                                    <Link to="/orders" style={{ color: 'inherit', textDecoration: 'none', width: '100%' }}>
                                        <OrdersProvider>
                                            <OrdersBox />
                                        </OrdersProvider>
                                    </Link>
                                </Grid>
                                {/* StatsBox - Takes 2x1 */}
                                {/* <Box sx={{ gridColumn: 'span 2', gridRow: 'span 1' }}>
                                <OrdersProvider>
                                    <StatsBox />
                                </OrdersProvider>
                            </Box> */}
                            </>
                        ) : (
                            /* OrdersBox for non-admin (2x1) */
                            <Grid item xs={12} sm={6}>
                                <Link to="/orders" style={{ color: 'inherit', textDecoration: 'none', display: 'flex', justifyContent: 'center' }}>
                                    <OrdersProvider>
                                        <OrdersBox />
                                    </OrdersProvider>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Stack>


            {/* Footer */}
            <Typography variant="body2" color="text.secondary" align="center" sx={{ marginTop: 'auto' }}>
                {'Copyright © '}
                <Link color="inherit" to="https://welcome.forknife.app/" style={{ textDecoration: 'none' }}>
                    Forknife
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Container>
    );
}
