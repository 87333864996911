import React from 'react';
import { Box, TextField, Fab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

const TitleField = ({ values, handleChange, handleBlur, touched, errors, toggleDrawer, update }) => (
    <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <TextField
            name="name"
            label="Nom du plat"
            required
            fullWidth
            variant='standard'
            sx={{ border: 0, fontWeight: 'bold !important', width: '80%' }}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            InputProps={{ textDecoration: 'none' }}
        />
        {update && (
            <Fab size="small" color='error' aria-label="delete">
                <DeleteIcon onClick={toggleDrawer(true)} />
            </Fab>
        )}
    </Box>
);

export default TitleField;
