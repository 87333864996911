import { PhoneOutlined, ShoppingBagOutlined, TableBarOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Alert, Box, Card, CardContent, Divider, Grid, Snackbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format, isSameDay, isSameWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { OrdersContext } from '../../../context/OrdersContext';
import '../../../css/loader.css';
import useMenu from '../../../hooks/useMenu';
import LongButton from '../../../shared-components/LongButton';
import OrderStatusChip from '../../../shared-components/OrderStatusChip';

const stopPropagationWrapper = (fn) => (event) => {
    event.stopPropagation();
    fn(event);
};

export const getStatusBackgroundColor = (status, theme) => {
    switch (status) {
        case 'CREATED':
            return theme.palette.primary.light;
        case 'CONFIRMED':
            return theme.palette.primary.dark;
        case 'VALIDATED':
            return theme.palette.background.paper;
        case 'CLOSED':
            return theme.palette.error.light;
        default:
            return theme.palette.background.default;
    }
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    if (isSameDay(date, now)) {
        return "aujourd'hui à " + format(date, 'HH:mm', { locale: fr });
    } else if (isSameWeek(date, now, { weekStartsOn: 1, locale: fr })) {
        return format(date, 'EEEE à HH:mm', { locale: fr }); // 'EEEE' gives the full name of the day in French
    } else {
        return format(date, 'dd/MM à HH:mm', { locale: fr });
    }
};

const OrderCard = ({ order, table }) => {
    const theme = useTheme();
    const { loading, currency } = useContext(AppContext);
    const { getFoodItemById } = useMenu();
    const { validateOrder, openOrder, closeOrder, deleteOrder } = useContext(OrdersContext);
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleEditOrder = stopPropagationWrapper(() => {
        navigate('/orders/edit', { state: { currentOrder: { order, table } } });
    });

    const handleValidateOrder = async () => {
        try {
            await validateOrder(table?.id || order.id);
            showSnackbar('Commande validée avec succès.');
        } catch (error) {
            showSnackbar('Échec de la validation de la commande.', 'error');
        }
    };

    const handleOpenOrder = async () => {
        try {
            await openOrder(table?.id || order.id);
            showSnackbar('Commande réouverte avec succès.');
        } catch (error) {
            showSnackbar('Échec de la réouverture de la commande.', 'error');
        }
    };

    const handleCloseOrder = async () => {
        const error = await closeOrder(table?.id || order.id);
        if (!error) {
            showSnackbar('Commande fermée avec succès.');
        }
        else {
            showSnackbar('Échec de la fermeture de la commande.', 'error');
        }
    };

    const handleDelete = async () => {
        try {
            await deleteOrder(table?.id || order.id);
            showSnackbar('Commande supprimée avec succès.');

        } catch (error) {
            showSnackbar('Échec de la suppression de la commande.', 'error');
        }
    };

    const handleCallContact = async () => {
        window.location.href = `tel:${order.contact}`;
    };

    if (loading || !currency) {
        return <Box display="flex" justifyContent="space-between" alignItems="center" width={"100%"} p={3}><div className='loader notcentered'></div></Box>
    }

    return (
        <>
            <Card
                onClick={handleToggleExpand}
                style={{
                    width: '100%',
                    marginBottom: '16px',
                    backgroundColor: getStatusBackgroundColor(order.status, theme),
                    border: '1px solid black',
                    borderRadius: '12px',
                    cursor: 'pointer'
                }}
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" flex={1}>
                            {table
                                ? <Box display="flex" justifyContent="flex-start" alignItems="center"><TableBarOutlined />&nbsp;Table #{table.name}</Box>
                                : <Box display="flex" justifyContent="flex-start" alignItems="center"><ShoppingBagOutlined />A emporter - {order.id.substring(order.id.length - 2)}</Box>}
                        </Typography>
                        <OrderStatusChip status={order.status} />
                    </Box>
                    <Box display="flex" flexDirection={'column'} py={2}>
                        {order.status == 'VALIDATED' && order.dateLastModified && (
                            <Typography component="p" variant="body2" color="text.primary" sx={{ textAlign: 'right' }}>
                                Dernière modification: {formatDate(order.dateLastModified)}
                            </Typography>
                        )}
                        <Typography component="p" variant="body2" color="text.primary" sx={{ textAlign: 'right' }}>
                            Création: {formatDate(order.dateCreated)}
                        </Typography>
                        {!expanded && order.foodItems.length > 0 && (
                            <Typography component="p" variant="body2" color="text.primary" sx={{ textAlign: 'left' }} pt={2}>
                                La commande contient: {order.foodItems.slice(0, 3).map(item => item.quantity + "x " + getFoodItemById(item.id).name).join(', ')}
                                {order.foodItems.length > 3 && "..."}
                            </Typography>
                        )}
                        {!expanded && order.foodItems.length === 0 && (
                            <Typography component="p" variant="body2" color="text.primary" sx={{ textAlign: 'left' }} pt={2}>
                                La commande ne contient aucun article.
                                {order.foodItems.length > 3 && "..."}
                            </Typography>
                        )}
                    </Box>
                    {
                        order.table
                            ? <Box>
                                <Typography variant='body2'># de personnes à table: {order.order.people.length}</Typography>
                            </Box>
                            : null
                    }
                    <AnimatePresence initial={false}>
                        {expanded && (
                            <motion.div
                                key="content"
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <Box display="flex" flexDirection="column">
                                    {table && <Typography variant='body2'># de personnes à table: {order.people.length}</Typography>}
                                    <Box display="flex" flexDirection="column" mb={1}>
                                        {order.foodItems.map((foodItem, index) => (
                                            <Box key={index} display="flex" flexDirection="column">
                                                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                                                <Typography component="p" variant="subtitle2">
                                                    {foodItem.quantity}x {getFoodItemById(foodItem.id).name} - {foodItem.totalPrice} {currency}
                                                </Typography>
                                                {foodItem.requiredChoices && Object.keys(foodItem.requiredChoices).map((option, idx) => (
                                                    <Typography key={idx} variant="body2" color="secondary.text">{`• ${option} - ${foodItem.requiredChoices[option]}`}</Typography>
                                                ))}
                                                {foodItem.extras && Object.keys(foodItem.extras).map((extra, idx) => (
                                                    <Typography key={idx} variant="body2" color="secondary.text">{`+ ${extra} - ${foodItem.extras[extra]} ${currency}`}</Typography>
                                                ))}
                                                {foodItem.additionalNote && <Typography variant="body2" color="secondary.text">{`- Note: ${foodItem.additionalNote}`}</Typography>}
                                            </Box>
                                        ))}
                                    </Box>
                                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                                    <Box display="flex" flexDirection="row" alignItems="flex-start" mb={2} gap="4px" width="100%" height="24px">
                                        <Typography variant="subtitle2" sx={{ flexGrow: 1, order: 0 }}>
                                            Sous-Total
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ flexGrow: 0, order: 1, textAlign: 'right' }}>
                                            {order.foodItems.reduce((total, item) => total + item.totalPrice, 0)} {currency}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="column" mb={2} width="100%" gap={2}>
                                        {order.status === 'CREATED' && (
                                            <>
                                                {!order.tableId && !order.contact && <Typography variant="body2">Pas de n° de tél. renseigné lors de la commande</Typography>}
                                                {!order.tableId && order.contact && <LongButton variant="primary" icon={PhoneOutlined} label={"Appeler " + order.contact} onClick={handleCallContact} />}
                                                <LongButton variant="primary" icon={CheckCircleOutlineIcon} label="Valider la commande" onClick={handleValidateOrder} />
                                                <LongButton variant="outlined" icon={EditIcon} label="Modifier avant validation" onClick={handleEditOrder} />
                                                <LongButton variant="disabled" icon={LockOpenIcon} label="Le client peut modifier la commande" />
                                                <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                            </>
                                        )}
                                        {order.status === 'CONFIRMED' && (
                                            <>
                                                {!order.tableId && <LongButton variant="primary" icon={PhoneOutlined} label={"Appeler " + order.contact} onClick={handleCallContact} />}
                                                <LongButton variant="primary" icon={CheckCircleOutlineIcon} label="Valider la commande" onClick={handleValidateOrder} />
                                                <LongButton variant="primary" icon={EditIcon} label="Modifier avant validation" onClick={handleEditOrder} />
                                                <LongButton variant="primary" icon={LockOpenIcon} label="Permettre modification client" onClick={handleOpenOrder} />
                                                <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                            </>
                                        )}
                                        {order.status === 'VALIDATED' && (
                                            <>
                                                <LongButton variant="primary" icon={KeyIcon} label={table ? "Libérer la table" : "Archiver la commande"} onClick={handleCloseOrder} />
                                                <LongButton variant="outlined" icon={EditIcon} label="Modifier la commande" onClick={handleEditOrder} />
                                                <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                            </>
                                        )}
                                        {order.status === 'CLOSED' && (
                                            <>
                                                <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDelete} />
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </CardContent>
            </Card>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default OrderCard;
