import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CIcon from "@coreui/icons-react";
import { cibFacebook, cibGoogle, cibInstagram, cibTiktok, cilXCircle } from "@coreui/icons";
import { AppBar, Backdrop, Card, CardContent, Container, Fade, IconButton, Modal, TextField, Toolbar } from "@mui/material";
import BackButton from "../../shared-components/BackButton";
import { AppContext } from "../../context/AppContext";
import '../../css/loader.css';
import FacebookIcon from '../../assets/images/facebook.svg';
import InstagramIcon from '../../assets/images/instagram.svg';
import GoogleIcon from '../../assets/images/google-maps.svg';
import tiktokIcon from '../../assets/images/tiktok.svg';
import { Google } from "@mui/icons-material";

function formatLink(link) {
    if (!link) return "Non défini";

    let withoutWebsite = link.split('.com/')[1];
    if (withoutWebsite.charAt(withoutWebsite.length - 1) === '/') withoutWebsite = withoutWebsite.substring(0, withoutWebsite.length - 1);
    if (withoutWebsite.charAt(0) === '@') return withoutWebsite;
    return '@' + withoutWebsite;
}

const SocialMediaItem = ({ src, icon, name = "", link, onOpen }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "nowrap",
                gap: 2,
                p: 2,
                boxShadow: 'none'
            }}
        >
            <Box sx={{ flexShrink: 0 }}>
                <Avatar variant="square" sx={{ background: 'transparent' }} >
                    <Box
                        component="img"
                        src={src}
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '16px',
                        }}
                    />
                </Avatar>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    minWidth: 0,
                    gap: 1,
                    alignItems: "center",
                    flexGrow: 1,
                    "& > *": {
                        minWidth: `clamp(0px, (240px + 1px - 100%) * 999, 100%)`
                    }
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        noWrap
                        sx={{
                            fontWeight: 600,
                            fontSize: "1rem",
                            color: (theme) =>
                                theme.palette.mode === "dark" ? "#fff" : "#122740"
                        }}
                    >
                        <b>{name}</b>
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: "0.875rem",
                            color: "#758392",
                            mt: -0.25
                        }}
                    >
                        {formatLink(link)}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        size="small"
                        variant="outlined"
                        sx={{
                            borderRadius: 5,
                            padding: "0.125rem 0.75rem",
                            borderColor: "#becddc",
                            fontSize: "0.75rem"
                        }}
                        onClick={() => onOpen(name, link)}
                    >
                        Modifier
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const SocialMediaModal = ({ icon, name, link, open, onClose }) => {
    const handleUpdate = () => {
        return onClose();
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-social-media"
            aria-describedby="modal-social-media-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "12px",
                        minWidth: 256,
                        textAlign: "center",
                        boxShadow:
                            "0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <CardContent>
                        <Avatar variant="square" sx={{ margin: "auto", background: 'transparent' }} >
                            <CIcon icon={icon} size="3xl" />
                        </Avatar>
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: 18,
                                fontWeight: "bold",
                                marginTop: 1,
                            }}
                        >
                            {name}
                        </Typography>
                        <Box display="flex" sx={{ margin: 'auto', mb: 3 }}>
                            <TextField label="Lien" variant="standard" defaultValue={link} helperText={`Collez ici le lien de votre page ${name}`} />
                        </Box>
                        <Box>
                            <Button variant="contained" onClick={handleUpdate}>Mettre à jour</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Fade>
        </Modal >
    );
};

const SocialMediaLinks = () => {
    const { data, loading } = useContext(AppContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState(<></>);
    const [selectedName, setSelectedName] = useState("");
    const [selectedLink, setSelectedLink] = useState("");

    const handleOpen = (icon, name, link) => {
        setSelectedIcon(icon);
        setSelectedName(name);
        setSelectedLink(link);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    if (loading || !data) {
        return <div className="loader"></div>
    };

    const links = data.eatery.links;

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 0 }}>
            <Box
                alignItems="baseline"
                p={2}
                sx={{
                    margin: 0,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: "wrap",
                    borderTopLeftRadius: "inherit",
                    borderTopRightRadius: "inherit",
                    bgcolor: (theme) =>
                        theme.palette.mode === "dark" ? "#2f3c50" : "#fff"
                }}
            >
                <AppBar position="static" sx={{ bgcolor: 'background.paper', boxShadow: 'none' }}>
                    <Toolbar>
                        <Box marginRight={2}>
                            <BackButton to='/' position="static" />
                        </Box>
                        <Box>
                            <Typography variant="h6" color={"MenuText"}>
                                <b>Réseaux sociaux</b>
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container spacing={3} sx={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 2,
                        bgcolor: (theme) =>
                            theme.palette.mode === "dark" ? "#1f2733" : "#fff",//"rgb(244, 247, 250)",
                    }}
                >
                    <SocialMediaItem
                        src={InstagramIcon}
                        icon={cibInstagram}
                        name="Instagram"
                        link={links["instagram"]}
                        onOpen={() => handleOpen(cibInstagram, "Instagram", links["instagram"])}
                    />
                    <Divider />
                    <SocialMediaItem
                        src={FacebookIcon}
                        icon={cibFacebook}
                        name="Facebook"
                        link={links["facebook"]}
                        onOpen={() => handleOpen(cibFacebook, "Facebook", links["facebook"])}
                    />
                    <Divider />
                    <SocialMediaItem
                        src={tiktokIcon}
                        icon={cibTiktok}
                        name="Tiktok"
                        link={links["tiktok"]}
                        onOpen={() => handleOpen(cibTiktok, "Tiktok", links["tiktok"])}
                    />
                    <Divider />
                    <SocialMediaItem
                        src={GoogleIcon}
                        icon={cibGoogle}
                        name="Google Reviews"
                        link={links["google"]}
                        onOpen={() => handleOpen(cibGoogle, "Google Reviews", links["google"])}
                    />
                    <SocialMediaModal
                        open={modalOpen}
                        onClose={handleClose}
                        icon={selectedIcon}
                        name={selectedName}
                        link={selectedLink}
                    />
                    <Divider />
                </Box>
            </Container>
        </Container>
    );
};

export default SocialMediaLinks;
