import React, { createContext, useContext, useEffect, useState } from 'react';
import useApi from '../api';
import { AppContext } from './AppContext';

const CategoryContext = createContext();

const CategoryProvider = ({ children }) => {
    const { menu, getMenu } = useContext(AppContext);
    const {
        getCategoriesApi,
        reorderCategoriesApi,
        createCategoryApi,
        updateCategoryApi,
        deleteCategoryApi
    } = useApi();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);

    const fetchCategories = async () => {
        const fetchedCategories = await getCategoriesApi();
        setCategories(fetchedCategories);
    }

    useEffect(() => {
        fetchCategories();
    }, [menu]);


    useEffect(() => {
        if ((!selectedCategory || !categories.find(c => c.id === selectedCategory.id))
            && categories.length) {
            setSelectedCategory(categories[0]);
        }
    }, [categories]);

    const reorderCategories = async (updatedCategories) => {
        try {
            await reorderCategoriesApi(updatedCategories);
            await getMenu();
        } catch (error) {
            console.error('Error reordering categories:', error);
        }
    };

    const addCategory = async (newCategory) => {
        try {
            await createCategoryApi(newCategory);
            await getMenu();
        } catch (error) {
            console.error('Error creating category:', error);
            return error;
        }
    };

    const editCategory = async (categoryId, updatedCategory) => {
        try {
            await updateCategoryApi(categoryId, updatedCategory);
            await getMenu();
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const deleteCategory = async (categoryId) => {
        await deleteCategoryApi(categoryId);
        await getMenu();

    };


    const findCategoryById = (id) => {
        return categories.find(c => c.id === id);
    }

    return (
        <CategoryContext.Provider value={{
            categories,
            selectedCategory,
            setSelectedCategory,
            reorderCategories,
            addCategory,
            editCategory,
            deleteCategory,
            findCategoryById
        }}>
            {children}
        </CategoryContext.Provider>
    );
};

export { CategoryContext, CategoryProvider };
