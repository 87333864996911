import { useTheme } from '@emotion/react';
import { Card, CardContent, Container, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Checkbox, Button, Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import useMenu from '../../hooks/useMenu';
import { Topbar } from '../../shared-components/Topbar';
import LongButton from '../../shared-components/LongButton';

const OutOfStockItems = () => {
    const theme = useTheme();
    const { eateryId } = useContext(AuthContext);
    const { data, setOutOfStock } = useContext(AppContext);
    const { menu } = useMenu(); // Assuming saveFoodItem is used to update food items
    const [outOfStockItems, setOutOfStockItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [filter, setFilter] = useState('');
    const [durationFilter, setDurationFilter] = useState('all');
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        // Filter menu to get out-of-stock items
        const outOfStock = Object.values(menu)
            .flatMap(categoryItems => categoryItems)
            .filter(item => item.outOfStock);
        setOutOfStockItems(outOfStock);
    }, [menu]);

    useEffect(() => {
        filterItems();
    }, [filter, durationFilter, outOfStockItems]);

    const filterItems = () => {
        let filtered = outOfStockItems;

        if (filter) {
            filtered = filtered.filter(item =>
                item.name.toLowerCase().includes(filter.toLowerCase()) ||
                (item.description && item.description.toLowerCase().includes(filter.toLowerCase()))
            );
        }

        if (durationFilter !== 'all') {
            const now = dayjs();
            filtered = filtered.filter(item => {
                const daysOutOfStock = now.diff(dayjs(item.outOfStockSince), 'day');
                switch (durationFilter) {
                    case 'lastDay':
                        return daysOutOfStock <= 1;
                    case 'last3Days':
                        return daysOutOfStock <= 3;
                    case 'lastWeek':
                        return daysOutOfStock <= 7;
                    case 'lastMonth':
                        return daysOutOfStock <= 30;
                    default:
                        return true;
                }
            });
        }

        setFilteredItems(filtered);
        setSelectedItems([]); // Reset selected items when the filter changes
    };

    const handleSelectAll = () => {
        if (selectedItems.length === filteredItems.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(filteredItems.map(item => item.id));
        }
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems(prev =>
            prev.includes(itemId) ? prev.filter(id => id !== itemId) : [...prev, itemId]
        );
    };

    const handleUpdateOutOfStock = async () => {
        for await (const itemId of selectedItems) {
            await setOutOfStock(itemId, false); // Update the outOfStock status to false
        }

        // Refresh the list
        const updatedItems = outOfStockItems.map(item =>
            selectedItems.includes(item.id) ? { ...item, outOfStock: false } : item
        ).filter(item => item.outOfStock);

        setOutOfStockItems(updatedItems);
        setFilteredItems(updatedItems.filter(item => item.name.toLowerCase().includes(filter.toLowerCase())));
        setSelectedItems([]);
    };

    if (!data || !Object.keys(menu).length) {
        return <div className='loader'></div>;
    }

    return (
        <Container maxWidth="sm" sx={{ padding: '16px', fontFamily: '"Inter", sans-serif', backgroundColor: 'background.default' }}>
            <Topbar name={'Articles en rupture de stock'} showBackButton />

            {/* Filters */}
            <TextField
                label="Recherche par nom ou description"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Filtrer par durée</InputLabel>
                <Select
                    value={durationFilter}
                    onChange={(e) => setDurationFilter(e.target.value)}
                    label="Filtrer par durée"
                >
                    <MenuItem value="all">Toutes durées</MenuItem>
                    <MenuItem value="lastDay">Dernier jour</MenuItem>
                    <MenuItem value="last3Days">Derniers 3 jours</MenuItem>
                    <MenuItem value="lastWeek">Dernière semaine</MenuItem>
                    <MenuItem value="lastMonth">Dernier mois</MenuItem>
                </Select>
            </FormControl>

            {/* Select All Checkbox */}
            <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: 2 }}>
                <Button onClick={handleSelectAll}>
                    {selectedItems.length === filteredItems.length ? 'Désélectionner tout' : 'Sélectionner tout'}
                </Button>
            </Box>

            {/* Displaying Out-of-Stock Items */}
            {filteredItems.length === 0 ? (
                <Typography variant="body" sx={{ textAlign: 'center', marginTop: 2 }}>
                    Aucun article en rupture de stock pour les filtres sélectionnés.
                </Typography>
            ) : (
                filteredItems.map((item, index) => (
                    <Card key={index} sx={{ marginBottom: '16px', backgroundColor: 'background.paper', border: '1px solid #000000', borderRadius: '12px' }}>
                        <CardContent>
                            <Box display="flex" alignItems="flex-start">
                                <Checkbox
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                                <Box flexGrow={1}>
                                    <Typography component="p" variant="h6">
                                        {item.name}
                                    </Typography>
                                    <Typography component="p" variant="body2" sx={{ marginBottom: 2 }}>
                                        {item.description}
                                    </Typography>
                                    {
                                        item.outOfStockSince
                                            ? <Typography component="p" variant="body2" sx={{ textAlign: 'right' }}>
                                                Rupture depuis: {dayjs(item.outOfStockSince).format('DD/MM/YYYY')}
                                            </Typography>
                                            : null
                                    }
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))
            )}

            {/* Button to Update Out-of-Stock Status */}
            {selectedItems.length > 0 && (
                <LongButton
                    variant="primary"
                    label={"Mettre à jour l'état des articles sélectionnés"}
                    onClick={handleUpdateOutOfStock}
                >

                </LongButton>
            )}
        </Container>
    );
};

export default OutOfStockItems;
