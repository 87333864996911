import React from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';  // Import French locale
import dayjs from 'dayjs';

const DateRangePicker = ({ startDate, endDate, onStartDateChange, onEndDateChange, sx }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={'fr'}>
            <Box display="flex" justifyContent="space-between" gap="16px" sx={sx}>
                <DatePicker
                    label="Date de début"
                    value={startDate}
                    onChange={onStartDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    maxDate={endDate || undefined}
                />
                <DatePicker
                    label="Date de fin"
                    value={endDate}
                    onChange={onEndDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    minDate={startDate || undefined}
                />
            </Box>
        </LocalizationProvider>
    );
};

export default DateRangePicker;
