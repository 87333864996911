import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const FailureSnackbar = ({ open, onClose, message }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={onClose}
        >
            <Alert severity="error" variant="filled" sx={{ width: '100%' }} onClose={onClose}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default FailureSnackbar;
