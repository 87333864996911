import React, { useContext, useEffect } from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import theme from "../theme";
import "../css/loader.css";
import { AppContext } from "../context/AppContext";

const Banner = ({ header }) => {
    const { data, loading } = useContext(AppContext);

    if (loading || !data) {
        return <div className="loader"></div>;
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <picture>
                        {data.eatery.theme.banner.webp && <source srcSet={data.eatery.theme.banner.webp} type="image/webp" />}
                        {data.eatery.theme.banner.avif && <source srcSet={data.eatery.theme.banner.avif} type="image/avif" />}
                        {data.eatery.theme.banner.jpg && <source srcSet={data.eatery.theme.banner.jpg} type="image/jpg" />}
                        <Box
                            component="img"
                            srcSet={`${data.eatery.theme.banner}`}
                            sx={{
                                width: '100%',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                                padding: 0,
                            }}
                        />
                    </picture>
                </Grid>
            </Grid>
            <Grid container sx={{ px: 2, pb: 2 }}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Container sx={{ textAlign: 'center', padding: 0 }}>
                        {theme.showLogoInMenu && (
                            <picture>
                                {data.eatery.theme.logo.webp && <source srcSet={data.eatery.theme.logo.webp} type="image/webp" />}
                                {data.eatery.theme.logo.avif && <source srcSet={data.eatery.theme.logo.avif} type="image/avif" />}
                                {data.eatery.theme.logo.jpg && <source srcSet={data.eatery.theme.logo.jpg} type="image/jpg" />}
                                <Box
                                    component="img"
                                    srcSet={`${data.eatery.theme.logo}`}
                                    sx={{
                                        background: 'white',
                                        height: '20vh',
                                        aspectRatio: '1/1',
                                        display: 'block',
                                        margin: '0 auto',
                                        marginTop: -8
                                    }} />
                            </picture>
                        )}
                        <Typography variant="h3">{header}</Typography>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default React.memo(Banner);
