import React, { useContext, useEffect } from 'react'
import { Topbar } from '../../shared-components/Topbar'
import CategoriesDraggableList from './components/CategoriesDraggableList'
import { AppContext } from '../../context/AppContext';
import '../../css/loader.css'
import { Box, Container, Typography } from '@mui/material';
import { CategoryContext } from '../../context/CategoryContext';

const Categories = () => {
  const { data, loading, error, menu } = useContext(AppContext);
  const { categories } = useContext(CategoryContext);

  const getLabel = (count, singular, plural) => (count === 1 ? singular : plural);

  if (loading || !data) {
    return <div className="loader"></div>
  };

  const categoryCount = categories.length;
  const articleCount = Object.values(menu).flat().length;
  const outOfStockCount = Object.values(menu).flat().filter(item => item.outOfStock).length;

  return (
    <Container maxWidth="sm" disableGutters style={{ paddingY: '16px', fontFamily: '"Inter", sans-serif' }}>
      <Topbar name="Mes catégories" showBackButton />
      <Box display={'flex'} width={"100%"} my={3}>
        <Box display={'flex'} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} flexGrow={1}>
          <Typography variant='h5' fontWeight={'bolder'}>{categoryCount}</Typography>
          <Typography variant='body' fontWeight={'bold'}>{getLabel(categoryCount, 'catégorie', 'catégories')}</Typography>
        </Box>
        <Box display={'flex'} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} flexGrow={1}>
          <Typography variant='h5' fontWeight={'bolder'}>{articleCount}</Typography>
          <Typography variant='body' fontWeight={'bold'}>{getLabel(articleCount, 'article', 'articles')}</Typography>
        </Box>
        <Box display={'flex'} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} flexGrow={1}>
          <Typography variant='h5' fontWeight={'bolder'}>{outOfStockCount}</Typography>
          <Typography variant='body' fontWeight={'bold'}>{getLabel(outOfStockCount, 'rupture', 'ruptures')}</Typography>
        </Box>
      </Box>
      <CategoriesDraggableList />
    </Container>
  )
}




export default Categories