import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrowBackIos, ArrowBackIosNew, ArrowLeft, ChevronLeft } from '@mui/icons-material';
import { useTheme } from '@mui/material';

function BackButton({ to, state, position = 'absolute', sx }) {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <IconButton
            size="medium"
            edge="start"
            color="black"
            aria-label="back"
            onClick={() => navigate(to || -1, { state })}
            sx={{
                position: position,
                ...(position === 'absolute' &&
                    "top: '2%', left: '5%', "
                ),
                margin: 0,
                zIndex: 100,
                //backgroundColor: 'white',
                //border: '1px solid',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.25)' // Customize hover state color
                },
                ...sx
            }}
        >
            {/* <ArrowBackIcon sx={{ fontSize: '1rem' }} /> */}
            <ArrowBackIosNew sx={{ color: 'black' }} />
        </IconButton>
    );
}

export default BackButton;
