import React, { useState } from 'react';
import { DndContext, DragOverlay, useSensor, useSensors, PointerSensor, TouchSensor, KeyboardSensor, closestCenter } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import TableCard from './TableCard';

const TablesDragContext = ({ tables, setTables, orders, editMode, expandedTable, handleTableClick, getTableStatus }) => {
    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const handleDragStart = (event) => {
        const { active } = event;
        setActiveId(active.id);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            setTables((prevTables) => {
                const oldIndex = prevTables.findIndex(table => table.name === active.id);
                const newIndex = prevTables.findIndex(table => table.name === over.id);
                return arrayMove(prevTables, oldIndex, newIndex);
            });
        }
        setActiveId(null);
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <SortableContext items={tables.map(table => table.name)} strategy={rectSortingStrategy}>
                {tables.map((table) => (
                    <SortableItem
                        key={table.name}
                        id={table.name}
                        disabled={!editMode}
                        isExpanded={expandedTable === table.name}
                    >
                        <TableCard
                            table={table}
                            order={orders.find(order => order.table?.id === table.id)}
                            isExpanded={expandedTable === table.name}
                            tableStatus={getTableStatus(table.id)}
                            onClick={() => handleTableClick(table.name)}
                        />
                    </SortableItem>
                ))}
            </SortableContext>
            <DragOverlay>{activeId ? <SortableItem id={activeId} /> : null}</DragOverlay>
        </DndContext>
    );
};

export default TablesDragContext;
