import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from "@mui/material";
import React, { useState } from "react";

const CategoryDialog = ({ open, handleClose, handleChange, handleSave, newCategory, editIndex }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSaveInternal = async () => {
        const error = handleSave(newCategory)
        if (error) {
            setSnackbarMessage(editIndex ? "Catégorie modifiée avec succès!" : "Catégorie créée avec succès!");
            setSnackbarSeverity("success");
            handleSave(editIndex ? "update-success" : "create-success");
        } else {
            setSnackbarMessage("Une erreur s'est produite, veuillez réessayer. Si le problème persiste, contactez l'administrateur.");
            setSnackbarSeverity("error");
        }
        setSnackbarOpen(true);

    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{editIndex !== null ? "Modifier la catégorie" : "Ajouter une nouvelle catégorie"}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="displayName"
                    label="Nom affiché dans le menu"
                    type="text"
                    fullWidth
                    value={newCategory.displayName}
                    onChange={handleChange}
                    sx={{ mb: 3 }}
                />
                <TextField
                    margin="dense"
                    name="shortName"
                    label="Nom affiché dans la barre de navigation"
                    // helperText="Nom affiché dans la barre de navigation"
                    type="text"
                    fullWidth
                    value={newCategory.shortName}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleSaveInternal}>{editIndex !== null ? "Modifier" : "Ajouter"}</Button>
            </DialogActions>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default CategoryDialog;
