import { useTheme } from "@emotion/react";
import { ArrowDownward, ArrowUpward, DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { Avatar, Box, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import LongButton from "../../../shared-components/LongButton";

const SimpleCard = ({ name, description, onClick, pullUp, pushDown, onEdit, onDelete }) => {
    const theme = useTheme();
    return (
        <Grid item xs={12} sx={{
            width: "100%",
            mb: 0,
            cursor: 'pointer',
            background: theme.palette.secondaryContainer.main,
            borderRadius: '12px',
            paddingX: 1,
            paddingY: 0,
            cursor: 'pointer',
            border: "2px solid " + theme.palette.outline.main,
        }}>
            <CardContent sx={{ padding: 0, display: 'flex', alignItems: 'center' }}>
                <Grid container alignItems="center">
                    <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '10%',
                                height: '100%',
                            }}
                            gap={3}
                            paddingY={0}
                            paddingX={1}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Avatar
                                onClick={pullUp}
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                }}
                            >
                                <ArrowUpward sx={{ color: theme.palette.primary.contrastText }} />
                            </Avatar>
                            <Avatar
                                onClick={pushDown}
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                }}
                            >
                                <ArrowDownward sx={{ color: theme.palette.primary.contrastText }} />
                            </Avatar>
                        </Box>
                    </Grid>
                    <Grid onClick={onClick} item xs={10} py={2} paddingX={2} display={'flex'} flexDirection={"column"} alignItems={"space-evenly"}>
                        <Typography variant="subtitle1" fontWeight={'500'} sx={{ lineHeight: '24px' }}>{name}</Typography>
                        <Typography variant="body2" fontWeight={'400'} mt={1}>S'affiche dans la barre de navigation comme:</Typography>
                        <Typography variant="body2" fontWeight={'500'} mt={1}>{description}</Typography>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} gap={1}>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }} mt={3} gap={1}>
                                <LongButton onClick={onEdit} variant={"outlined"} icon={EditOutlined} label={"Renommer"} sx={{ height: "40px", borderRadius: '8px' }} />
                                <LongButton onClick={onDelete} variant={"error"} icon={DeleteOutlineOutlined} label={"Supprimer"} sx={{ backgroundColor: 'transparent', borderRadius: '8px', height: "40px" }} />
                                {/* <Avatar

                                sx={{
                                    width: '35px',
                                    height: '35px',
                                    maxWidth: '60px',
                                    aspectRatio: '1/1',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'inherit',
                                    border: "1px solid"
                                }}
                            >
                                <EditOutlined sx={{ color: theme.palette.primary.contrastText }} />
                            </Avatar>
                            <Avatar
                                onClick={onDelete}
                                sx={{
                                    width: '35px',
                                    height: '35px',
                                    maxWidth: '60px',
                                    aspectRatio: '1/1',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: theme.palette.error.main,
                                    color: theme.palette.primary.contrastText,
                                    border: "1px solid"
                                }}
                            >
                                <DeleteOutlineOutlined />
                            </Avatar> */}
                            </Box>
                            <LongButton onClick={onClick} variant={"outlined"} label={"Voir les articles"} sx={{ backgroundColor: 'transparent', borderRadius: '8px', height: "40px" }} />
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Grid>
    );
};

export default SimpleCard;