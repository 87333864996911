import React from 'react';
import { Box, TextField, IconButton, Button, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { getIn } from 'formik';
import theme from '../../../../theme';
import LongButton from '../../../../shared-components/LongButton';

const RequiredChoicesField = ({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => {

    const handleDeleteChoice = (index) => {
        const updatedChoices = values.requiredChoices.filter((_, i) => i !== index);
        setFieldValue('requiredChoices', updatedChoices);
    };

    return (
        <Box sx={{ mb: 2, width: '100%', border: '1px solid', borderColor: theme.palette.outline.main, borderRadius: '8px', background: theme.palette.surface.container }} padding={2}>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <InputLabel required htmlFor="requiredChoices-form" sx={{ transform: 'scale(0.75)' }}>
                    Choix requis
                </InputLabel>
            </Box>
            {values.requiredChoices.map((choice, index) => (
                <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid', borderColor: theme.palette.outline.main, borderRadius: '8px', background: theme.palette.container.primary.lowest, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TextField
                            name={`requiredChoices[${index}].name`}
                            label="Nom du choix (Exemple: Sauce)"
                            variant='standard'
                            fullWidth
                            sx={{ mb: 2 }}
                            value={choice.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getIn(touched, `requiredChoices[${index}].name`) && Boolean(getIn(errors, `requiredChoices[${index}].name`))}
                            helperText={getIn(touched, `requiredChoices[${index}].name`) && getIn(errors, `requiredChoices[${index}].name`)}
                            InputProps={{ textDecoration: 'none', typography: 'body2' }}
                        />
                        <IconButton onClick={() => handleDeleteChoice(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {choice.options.map((option, optIndex) => (
                            <Box key={optIndex} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <TextField
                                    name={`requiredChoices[${index}].options[${optIndex}].choice`}
                                    label="Option"
                                    placeholder='ex. Algérienne'
                                    variant='standard'
                                    sx={{ mr: 1, flexGrow: 1 }}
                                    value={option.choice}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={getIn(touched, `requiredChoices[${index}].options[${optIndex}].choice`) && Boolean(getIn(errors, `requiredChoices[${index}].options[${optIndex}].choice`))}
                                    helperText={getIn(touched, `requiredChoices[${index}].options[${optIndex}].choice`) && getIn(errors, `requiredChoices[${index}].options[${optIndex}].choice`)}
                                    InputProps={{ textDecoration: 'none' }}
                                />
                                <TextField
                                    name={`requiredChoices[${index}].options[${optIndex}].price`}
                                    label="Prix"
                                    variant='standard'
                                    sx={{ width: '30%', mr: 1 }}
                                    value={option.price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={getIn(touched, `requiredChoices[${index}].options[${optIndex}].price`) && Boolean(getIn(errors, `requiredChoices[${index}].options[${optIndex}].price`))}
                                    helperText={getIn(touched, `requiredChoices[${index}].options[${optIndex}].price`) && getIn(errors, `requiredChoices[${index}].options[${optIndex}].price`)}
                                    InputProps={{ textDecoration: 'none' }}
                                />
                            </Box>
                        ))}
                        <LongButton
                            variant="outlined"
                            icon={AddIcon}
                            label={'Ajouter une option'}
                            onClick={() => setFieldValue(`requiredChoices[${index}].options`, [...choice.options, { choice: '', price: '' }])}
                        >

                        </LongButton>
                    </Box>
                </Box>
            ))}
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => setFieldValue('requiredChoices', [...values.requiredChoices, { name: '', options: [] }])}
            >
                Ajouter un choix requis
            </Button>
        </Box>
    );
};

export default RequiredChoicesField;
