import React, { useState } from 'react';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterChip from './FilterChip';

const FilterChips = ({ onFilterChange }) => {
  const theme = useTheme();
  const [selectedChips, setSelectedChips] = useState({
    FREE: true,
    PENDING: true,
    OCCUPIED: true,
    TO_VALIDATE: true,
  });

  const handleChipClick = (chip) => {
    const updatedChips = {
      ...selectedChips,
      [chip]: !selectedChips[chip],
    };
    setSelectedChips(updatedChips);
    onFilterChange(updatedChips); // Notify parent of the filter change
  };

  const handleClearFilters = () => {
    const resetChips = {
      FREE: true,
      PENDING: true,
      OCCUPIED: true,
      TO_VALIDATE: true,
    };
    setSelectedChips(resetChips);
    onFilterChange(resetChips); // Notify parent of the filter reset
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={1} px={3} width="100%"
      sx={{
        overflowY: 'hidden',
      }}>
      <Grid width={"100%"} display={"flex"} gap={2} sx={{ overflowX: "scroll" }}>
        <IconButton onClick={handleClearFilters}>
          <ClearIcon />
        </IconButton>
        <FilterChip
          label="Libre"
          backgroundColor={selectedChips.FREE ? theme.palette.background.paper : theme.palette.background.paper}
          textColor={selectedChips.FREE ? theme.palette.text.primary : theme.palette.text.disabled}
          borderColor="#CAC4D0"
          selected={selectedChips.FREE}
          onClick={() => handleChipClick('FREE')}
        />
        <FilterChip
          label="En cours"
          backgroundColor={selectedChips.PENDING ? theme.palette.primary.inverse : theme.palette.background.paper}
          textColor={selectedChips.PENDING ? theme.palette.text.primary : theme.palette.text.disabled}
          selected={selectedChips.PENDING}
          onClick={() => handleChipClick('PENDING')}
        />
        <FilterChip
          label="Occupée"
          backgroundColor={selectedChips.OCCUPIED ? theme.palette.primary.dark : theme.palette.background.paper}
          textColor={selectedChips.OCCUPIED ? theme.palette.text.primary : theme.palette.text.disabled}
          selected={selectedChips.OCCUPIED}
          onClick={() => handleChipClick('OCCUPIED')}
        />
        <FilterChip
          label="A Valider"
          backgroundColor={selectedChips.TO_VALIDATE ? theme.palette.primary.main : theme.palette.background.paper}
          textColor={selectedChips.TO_VALIDATE ? theme.palette.common.white : theme.palette.text.disabled}
          selected={selectedChips.TO_VALIDATE}
          onClick={() => handleChipClick('TO_VALIDATE')}
        />
      </Grid>
    </Box>
  );
};

export default FilterChips;
