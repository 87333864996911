import React from 'react';
import { Box, TextField, Button, IconButton, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import theme from '../../../../theme';

const ExtrasField = ({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => (
    <Box sx={{ mb: 2, width: '100%', border: '1px solid', borderColor: theme.palette.outline.main, borderRadius: '8px', background: theme.palette.surface.container }} padding={2}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InputLabel required htmlFor="extras-form" sx={{ transform: 'scale(0.75)' }}>
                Extras
            </InputLabel>
        </Box>
        {values.extras.map((extra, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                    name={`extras.${index}.name`}
                    label="Nom de l'extra"
                    variant='standard'
                    sx={{ mr: 1, flexGrow: 1 }}
                    value={extra.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.extras?.[index]?.name && Boolean(errors.extras?.[index]?.name)}
                    helperText={touched.extras?.[index]?.name && errors.extras?.[index]?.name}
                    InputProps={{ textDecoration: 'none' }}
                />
                <TextField
                    name={`extras.${index}.price`}
                    label="Prix"
                    variant='standard'
                    sx={{ width: '30%', mr: 1 }}
                    value={extra.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.extras?.[index]?.price && Boolean(errors.extras?.[index]?.price)}
                    helperText={touched.extras?.[index]?.price && errors.extras?.[index]?.price}
                    InputProps={{ textDecoration: 'none' }}
                />
                <IconButton onClick={() => setFieldValue('extras', values.extras.filter((_, i) => i !== index))}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        ))}
        <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setFieldValue('extras', [...values.extras, { name: '', price: '' }])}
        >
            Ajouter un extra
        </Button>
    </Box>
);

export default ExtrasField;
