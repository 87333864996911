const dev = {
    "API_URL": "http://localhost:8080",
    "DEMO_EATERY_ID": "65aeeae55ff43d39fb50a90c"
}

const staging = {
    "API_URL": "http://64.226.112.240:8080",
    "DEMO_EATERY_ID": "65aeeae55ff43d39fb50a90c"
}

const prod = {
    "API_URL": "http://64.226.112.240:8080",
    "DEMO_EATERY_ID": "65aeeae55ff43d39fb50a90c"
}
console.log('process', process.env.NODE_ENV);
export const config = process.env.NODE_ENV === 'development' ? dev : process.env.NODE_ENV === 'staging' ? staging : prod;