import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

const QuantitySelector = ({ initialQuantity = 1, onChange }) => {
    const [quantity, setQuantity] = useState(initialQuantity);
    const theme = useTheme();

    const handleIncrement = () => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        if (onChange) {
            onChange(newQuantity);
        }
    };

    const handleDecrement = () => {
        const newQuantity = quantity - 1;
        setQuantity(newQuantity);
        if (onChange) {
            onChange(newQuantity);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, width: 116, height: 48, background: theme.palette.secondary.main, borderRadius: 28 }}>
            <IconButton
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '4px 0px',
                    width: 42,
                    height: 48,
                    backgroundColor: theme.palette.secondary.light,
                    borderRadius: '100px 0px 0px 100px',
                    border: `1px solid ${theme.palette.text.primary}`,
                    borderRight: 'none'
                }}
                onClick={handleDecrement}
            >
                <Remove sx={{ color: theme.palette.text.primary }} />
            </IconButton>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    width: 32,
                    height: 48,
                    backgroundColor: theme.palette.secondary.light,
                }}
            >
                <Typography
                    variant="body"
                    sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: '20px',
                        color: theme.palette.text.primary,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        letterSpacing: '0.1px',
                        height: '100%',
                        width: '100%',
                        borderTop: `1px solid ${theme.palette.text.primary}`,
                        borderBottom: `1px solid ${theme.palette.text.primary}`
                    }}
                >
                    {quantity}
                </Typography>
            </Box>
            <IconButton
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '4px 0px',
                    width: 42,
                    height: 48,
                    backgroundColor: theme.palette.secondary.light,
                    borderRadius: '0px 100px 100px 0px',
                    border: `1px solid ${theme.palette.text.primary}`,
                    borderLeft: 'none'
                }}
                onClick={handleIncrement}
            >
                <Add sx={{ color: theme.palette.text.primary }} />
            </IconButton>
        </Box>
    );
};

export default QuantitySelector;
