import React, { useContext } from "react";
import { Grid } from "@mui/material";
import SimpleCard from "./SimpleCard";
import { useNavigate } from "react-router-dom";
import { CategoryContext } from "../../../context/CategoryContext";

const CategoryList = ({ categories, pullUp, pushDown, handleEdit, handleDelete, parentRef }) => {
    const navigate = useNavigate();
    const { setSelectedCategory } = useContext(CategoryContext);

    const handleNavigate = (category) => {
        setSelectedCategory(category);
        navigate("/menu");
    };

    return (
        <Grid container spacing={0.5} gap={2} my={3} ref={parentRef}>
            {categories.map((category, index) => (
                <SimpleCard
                    key={category.id}
                    name={category.displayName}
                    description={category.shortName}
                    onClick={() => handleNavigate(category)}
                    pullUp={(e) => pullUp(e, index)}
                    pushDown={(e) => pushDown(e, index)}
                    onEdit={(e) => handleEdit(e, category.id)}
                    onDelete={(e) => handleDelete(e, category.id)}
                />
            ))}
        </Grid>
    );
};

export default CategoryList;
