import React, { useEffect, useContext } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import theme from "../../../theme";
import { CategoryContext } from "../../../context/CategoryContext";
import '../../../css/loader.css';

const CategoriesNav = () => {
  const { categories, selectedCategory, setSelectedCategory } = useContext(CategoryContext);

  const handleCategoryClick = (event, categoryId) => {
    setSelectedCategory(categories.find(category => category.id === categoryId));
    //onCategoryChange(categories.find(category => category.id === categoryId));
  };

  if (!selectedCategory || !categories) {
    return <div className="loader"></div>;
  }

  return (
    <Grid container style={{ background: theme.backgroundColor }} mb={3}>
      <Grid item xs={12}>
        <Tabs
          id="h-nav"
          variant="scrollable"
          scrollButtons="auto"
          value={selectedCategory.id}
          onChange={handleCategoryClick}
          sx={{
            borderColor: theme.borderColor,
            backgroundColor: theme.backgroundColor,
            display: 'flex',
            overflowX: 'auto',
            flexWrap: 'nowrap',
            overflowY: 'hidden',
          }}
        >
          {categories.map((category) => (
            <Tab
              key={category.id}
              label={category.shortName}
              value={category.id}
              style={{
                fontWeight: "bold",
                display: 'flex',
                whiteSpace: 'nowrap'
              }}
            />
          ))}
        </Tabs>
      </Grid>
    </Grid>
  );
};

export default React.memo(CategoriesNav);
