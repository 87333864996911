import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CategoryField = ({ values, handleChange, categories }) => (
    <Box sx={{ mb: 2, width: '100%' }}>
        <FormControl fullWidth>
            <InputLabel required htmlFor="category-select" sx={{ transform: 'scale(0.75)' }}>
                Catégorie
            </InputLabel>
            <Select
                name="category"
                label="Catégorie"
                variant='standard'
                size='small'
                value={values.category}
                onChange={handleChange}
                sx={{ width: '100%', border: 0 }}
                inputProps={{ id: 'category-select' }}
            >
                {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                        {category.displayName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Box>
);

export default CategoryField;
