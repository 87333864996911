import React, { useContext, useState } from 'react';
import { AppBar, Box, Toolbar, Typography, IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import BackButton from './BackButton';
import { ChevronLeft, MoreVert } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrdersContext } from '../context/OrdersContext';
import { useError } from '../ErrorContext';

export const Topbar = ({ name, subtitle = "", showBackButton = false }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const showError = useError();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCategoriesPageClick = () => {
        navigate('/categories');
    };

    const handleOutOfStockItemsPageClick = () => {
        navigate('/out-of-stock');
    }

    const handleNewTakeoutOrderClick = () => {
        navigate('/orders/edit', { state: { currentOrder: null } });
    }

    const handleArchivedClick = () => {
        navigate('/orders/archived');
        handleMenuClose();
    };

    // const handleClearAllClick = () => {
    //     try {
    //         clearOrders();
    //     } catch (error) {
    //         showError("Une erreur s'est produite.");
    //     } finally {
    //         handleMenuClose();
    //     }
    // };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'background.default', boxShadow: 'none', mb: 2 }}>
            <Toolbar>
                {showBackButton &&
                    (<Box marginRight={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <BackButton position="static" />
                    </Box>)
                }
                <Box flexGrow={1}>
                    <Typography variant="h5" fontWeight={'bold'} sx={{ color: 'black' }}>
                        {name}
                    </Typography>
                    {
                        subtitle
                            ? <Typography variant="body2" fontWeight={'normal'} sx={{ color: 'black' }}>
                                {subtitle}
                            </Typography>
                            : null
                    }
                </Box>
                {['/categories', '/menu'].includes(location.pathname) && (
                    <>
                        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
                            <MoreVert sx={{ color: 'black' }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={handleCategoriesPageClick}>Réorganiser les catégories</MenuItem>
                            <MenuItem onClick={handleOutOfStockItemsPageClick}>Produits indisponibles</MenuItem>
                        </Menu>
                    </>
                )}
                {location.pathname === '/orders' && (
                    <>
                        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
                            <MoreVert sx={{ color: 'black' }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={handleNewTakeoutOrderClick}>Nouvelle commande à emporter</MenuItem>
                            <MenuItem onClick={handleArchivedClick}>Commandes archivées</MenuItem>
                        </Menu>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};
