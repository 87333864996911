import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";

const DeleteDialog = ({ open, handleClose, handleDeleteConfirm }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Supprimer la catégorie</DialogTitle>
            <DialogContent>
                <DialogContentText variant="subtitle2">
                    Êtes-vous sûr de vouloir supprimer cette catégorie ?
                    <br />
                    <Typography variant="caption" color="error">
                        Attention: Cette action entraînera la suppression de tous les articles sous cette catégorie.
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleDeleteConfirm} color="error">Supprimer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
