import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography, Badge } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/NotesOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBookOutlined';
import TableChartIcon from '@mui/icons-material/TableRestaurantOutlined';
import theme from '../theme';
import { OrdersContext } from '../context/OrdersContext';
import { AuthContext } from '../context/AuthContext';

const BottomNavbar = () => {
    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);
    const { user } = useContext(AuthContext);
    const { orders } = useContext(OrdersContext);
    const [ordersToValidateCount, setOrdersToValidateCount] = useState();

    useEffect(() => {
        if (!orders) return;

        setOrdersToValidateCount(
            orders.map(tableOrder => tableOrder.order).filter(order => {
                order.status === 'CONFIRMED'
            }).length
        );
    }, [orders]);

    useEffect(() => {
        if (["/", "/menu", "/orders", "/tables"].includes(location.pathname))
            setSelected(location.pathname);
    }, [location]);

    const NavItem = ({ to, icon: Icon, label, count }) => (
        <Link to={to} style={{ textDecoration: 'none', width: '25%' }} onClick={() => setSelected(to)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    height: '80px',
                    ...(selected === to && {
                        borderRadius: '16px',
                        '& .icon-container': {
                            background: theme.palette.primary.dark,
                            width: '64px',
                            height: '32px',
                            borderRadius: '8px',
                        },
                        '& .label': {
                            fontWeight: 'bold',
                            color: 'text.primary',
                        },
                    }),
                }}
            >
                <Box
                    className={selected === to ? 'icon-container' : ''}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',  // Add position relative to parent
                        ...(selected === to && {
                            background: theme.palette.primary.dark,
                            width: '64px',
                            height: '32px',
                            borderRadius: '8px',
                        }),
                    }}
                >
                    <Badge
                        badgeContent={count}
                        color="error"
                        sx={{ '& .MuiBadge-badge': { right: -3, top: 3 } }} // Adjust badge position
                    >
                        <Icon fontSize="medium" />
                    </Badge>
                </Box>
                <Typography variant="body2" color={selected === to ? 'text.primary' : 'text.secondary'} className="label">
                    {label}
                </Typography>
            </Box>
        </Link>
    );

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                height: '10%',
                width: '100%',
                background: theme.palette.secondaryContainer.main,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                '& a': {
                    '&:active': {
                        color: 'inherit', // Ensures the text color does not change when active
                    },
                },
            }}
            gap={1}
        >
            <NavItem to="/" icon={HomeIcon} label="Accueil" />
            {user.role === "ADMIN" && <NavItem to="/menu" icon={MenuBookIcon} label="Menu" />}
            <NavItem to="/orders" icon={ShoppingCartIcon} label="Commandes" count={ordersToValidateCount} />
            <NavItem to="/tables" icon={TableChartIcon} label="Tables" />
        </Box>
    );
};

export default BottomNavbar;
