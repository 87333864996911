import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import theme from '../../../../theme';
import { useFormikContext } from 'formik';

const PriceField = ({ currency }) => {
    const { values, touched, errors, setFieldValue, handleBlur } = useFormikContext();

    // Helper function to validate and format price
    const validatePrice = (value) => {
        const regex = /^\d+(\.\d{0,2})?$/; // Allow numbers with up to 2 decimal places
        if (value === '' || regex.test(value)) {
            return value;
        }
        return values.price; // Return the current value if it doesn't match the regex
    };

    const handleChange = (event) => {
        const { value } = event.target;
        const formattedValue = validatePrice(value.replace(',', '.')); // Replace commas with periods
        setFieldValue('price', formattedValue);
    };

    return (
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'end', mb: 2, border: '1px solid', borderColor: theme.palette.outline.main, borderRadius: '8px', background: theme.palette.surface.container }} padding={2}>
            <TextField
                name="price"
                label="Prix"
                variant='standard'
                size='small'
                placeholder='XXX.XX'
                inputMode='numeric'
                sx={{ width: '70%', border: 0 }}
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.price && Boolean(errors.price)}
                helperText={touched.price && errors.price}
            />
            <Typography variant="subtitle2" sx={{ ml: 1 }}>{currency}</Typography>
        </Box>
    );
};

export default PriceField;
