import React, { useContext, useState } from "react";
import { Box, Button, CardContent, Container, Snackbar, useTheme } from "@mui/material";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { CategoryContext } from "../../../context/CategoryContext";
import AddCategoryButton from "./AddCategoryButton";
import CategoryDialog from "./CategoryDialog";
import DeleteDialog from "./DeleteDialog";
import CategoryList from "./CategoryList";

const CategoriesDraggableList = () => {
    const theme = useTheme();
    const {
        categories,
        reorderCategories,
        addCategory,
        editCategory,
        deleteCategory
    } = useContext(CategoryContext);

    const [parent] = useAutoAnimate();
    const [savePopup, showSavePopup] = useState(false);
    const [open, setOpen] = useState(false);
    const [editCategoryId, setEditCategoryId] = useState(null);
    const [newCategory, setNewCategory] = useState({ displayName: "", shortName: "" });
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    // Store the initial categories to allow cancellation
    const [initialCategories, setInitialCategories] = useState(categories);

    function swap(array, i, j) {
        [array[i], array[j]] = [array[j], array[i]];
    }

    const pullUp = (e, index) => {
        e.stopPropagation();
        if (index > 0 && index < categories.length) {
            const updatedCategories = [...categories];
            swap(updatedCategories, index, index - 1);
            reorderCategories(updatedCategories);
            //showSavePopup(true);
        }
    };

    const pushDown = (e, index) => {
        e.stopPropagation();
        if (index >= 0 && index < categories.length - 1) {
            const updatedCategories = [...categories];
            swap(updatedCategories, index, index + 1);
            reorderCategories(updatedCategories);
            //showSavePopup(true);
        }
    };

    const handleSaveCategory = async (status) => {
        setOpen(false);
        if (status === "create-success") {
            await addCategory(newCategory);
        } else if (status === "update-success") {
            await editCategory(editCategoryId, newCategory);
        }
    };

    const handleEdit = (e, id) => {
        e.stopPropagation();
        setEditCategoryId(id);
        setNewCategory(categories.find(c => c.id === id));
        setOpen(true);
    };

    const handleDeleteConfirm = async () => {
        await deleteCategory(deleteId);
        setDeleteAlertOpen(false);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        setDeleteId(id);
        setDeleteAlertOpen(true);
    };

    const handleClickOpen = () => {
        setEditCategoryId(null);
        setNewCategory({ displayName: "", shortName: "" });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAlertClose = () => {
        setDeleteAlertOpen(false);
    };

    // Cancel the reordering and reset the categories
    const cancel = () => {
        reorderCategories(initialCategories);  // Reset to initial categories
        showSavePopup(false);  // Hide the save popup
    };

    const actions = (
        <div>
            <Button onClick={reorderCategories}>Enregistrer</Button>
            <Button onClick={cancel}>Annuler</Button>
        </div>
    );

    return (
        <Container>
            {(!categories || categories?.length === 0) &&
                <Box sx={{
                    background: theme.palette.secondaryContainer.main
                }} my={3}>
                    <CardContent sx={{ textAlign: 'center' }}>
                        Créez votre menu en créant des catégories puis ajoutez leur des articles.
                    </CardContent>
                </Box>
            }
            <AddCategoryButton onClick={handleClickOpen} />
            <CategoryList
                categories={categories}
                pullUp={pullUp}
                pushDown={pushDown}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                parentRef={parent}
            />
            <Snackbar
                open={savePopup}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message="Enregistrer les modifications?"
                action={actions}
                sx={{
                    '& .MuiSnackbarContent-message': {
                        font: theme.typography.labelLarge,
                        fontSize: '18px',
                        color: theme.palette.primary.main,
                        lineHeight: '20px',
                    },
                    '& .MuiSnackbarContent-root': {
                        background: theme.palette.primary.dark
                    }
                }}
            />
            <CategoryDialog
                open={open}
                handleClose={handleClose}
                handleChange={(e) => setNewCategory({ ...newCategory, [e.target.name]: e.target.value })}
                handleSave={handleSaveCategory}
                newCategory={newCategory}
                editIndex={editCategoryId}
            />
            <DeleteDialog
                open={deleteAlertOpen}
                handleClose={handleAlertClose}
                handleDeleteConfirm={handleDeleteConfirm}
            />
        </Container>
    );
};

export default CategoriesDraggableList;
