import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete, EditNote, TableRestaurantOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField
} from '@mui/material';
import { motion } from 'framer-motion';
import useMenu from '../../../hooks/useMenu';
import LongButton from '../../../shared-components/LongButton';
import theme from '../../../theme';
import '../../../css/heartbeat.css';
import { OrdersContext } from '../../../context/OrdersContext';
import { AppContext } from '../../../context/AppContext';
import { AuthContext } from '../../../context/AuthContext';

const stopPropagationWrapper = (fn) => (event) => {
    event.stopPropagation();
    fn(event);
};

const TableCard = ({ table, order, isExpanded, onClick, tableStatus }) => {
    const navigate = useNavigate();
    const { getFoodItemById } = useMenu();
    const { user } = useContext(AuthContext);
    const { editTableName, deleteTable } = useContext(AppContext);
    const { getOrderByTableId, createOrder, validateOrder, openOrder, closeOrder, deleteOrder } = useContext(OrdersContext);

    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [newTableName, setNewTableName] = useState(table.name);

    const getBackgroundColor = (status) => {
        switch (status) {
            case 'FREE':
                return theme.palette.common.white;
            case 'PENDING':
                return theme.palette.primary.inverse;
            case 'OCCUPIED':
                return theme.palette.primary.dark;
            case 'TO_VALIDATE':
                return theme.palette.primary.main;
            default:
                return theme.palette.common.white;
        }
    };

    const getStatusTranslation = (status) => {
        switch (status) {
            case 'FREE':
                return 'Libre';
            case 'PENDING':
                return 'Commande en cours';
            case 'OCCUPIED':
                return 'Occupée';
            case 'TO_VALIDATE':
                return 'A Valider';
            default:
                return '';
        }
    };

    const handleCreateOrder = stopPropagationWrapper(async () => {
        const newOrder = await createOrder('IN_TABLE', table.id);
        navigate('/orders/edit', { state: { currentOrder: newOrder } });
    });

    const handleEditOrder = stopPropagationWrapper((e) => {
        const order = getOrderByTableId(table.id);
        if (!order) {
            return handleCreateOrder();
        }
        navigate('/orders/edit', { state: { currentOrder: order } });
    });

    const handleDeleteOrder = stopPropagationWrapper(() => {
        deleteOrder(table.id);
    });

    const handleOpenEditDialog = stopPropagationWrapper(() => {
        setEditDialogOpen(true);
    });

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
    };

    const handleEditTableName = async () => {
        if (newTableName && newTableName !== table.name) {
            await editTableName(table.id, newTableName);
        }
        handleCloseEditDialog();
    };

    const handleOpenDeleteDialog = stopPropagationWrapper(() => {
        setDeleteDialogOpen(true);
    });

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteTable = async () => {
        await deleteTable(table.id);
        handleCloseDeleteDialog();
    };

    const renderExpandedContent = (status) => {
        switch (status) {
            case 'FREE':
                return (
                    <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%" padding={2}>
                        <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%">
                            {!order ? <LongButton
                                variant="primary"
                                icon={AddIcon}
                                label="Créer une nouvelle commande"
                                onClick={handleCreateOrder}
                            />
                                : <>
                                    <LongButton variant="outlined" icon={EditIcon} label="Modifier la commande" onClick={handleEditOrder} />
                                    <LongButton variant="primary" icon={KeyIcon} label="Valider la table" onClick={stopPropagationWrapper(() => { validateOrder(table.id) })} />
                                </>
                            }
                            {user.role === "ADMIN" && <LongButton variant="outlined" icon={TableRestaurantOutlined} label="Editer la table" onClick={handleOpenEditDialog} />}
                            {user.role === "ADMIN" && <LongButton variant="outlined" icon={Delete} label="Supprimer la table" onClick={handleOpenDeleteDialog} />}

                        </Box>
                    </Box>
                );
            case 'PENDING':
                return (
                    <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%" padding={2}>
                        <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%">
                            <LongButton variant="outlined" icon={EditIcon} label="Modifier la commande" onClick={handleEditOrder} />
                            <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={stopPropagationWrapper(() => { deleteOrder(table.id) })} />
                        </Box>
                    </Box>
                );
            case 'OCCUPIED':
                return (
                    <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%" padding={2}>
                        <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%">
                            <LongButton variant="primary" icon={KeyIcon} label="Libérer la table" onClick={stopPropagationWrapper(() => { closeOrder(table.id) })} />
                            <LongButton variant="outlined" icon={EditIcon} label="Modifier la commande" onClick={handleEditOrder} />
                            <LongButton variant="outlined" icon={LockOpenIcon} label="Permettre modification client" onClick={stopPropagationWrapper(() => { openOrder(table.id) })} />
                            <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={stopPropagationWrapper(() => { deleteOrder(table.id) })} />
                        </Box>
                    </Box>
                );
            case 'TO_VALIDATE':
                return (
                    <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%" padding={2}>
                        <Box display="flex" flexDirection="column" alignItems="center" gap="24px" width="100%">
                            <LongButton variant="inverse-outlined" icon={KeyIcon} label="Valider la table" onClick={stopPropagationWrapper(() => { validateOrder(table.id) })} />
                            <LongButton variant="inverse-outlined" icon={EditIcon} label="Modifier la commande" onClick={handleEditOrder} />
                            <LongButton variant="inverse-outlined" icon={LockOpenIcon} label="Permettre modification client" onClick={stopPropagationWrapper(() => { openOrder(table.id) })} />
                            <LongButton variant="error" icon={DeleteOutlineIcon} label="Supprimer la commande" onClick={handleDeleteOrder} />
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };

    const backgroundColor = getBackgroundColor(tableStatus);

    return (
        <>
            <motion.div
                layout
                initial={{ borderRadius: 16 }}
                onClick={() => onClick(table.name)}
                style={{
                    width: isExpanded ? '100%' : '81px',
                    aspectRatio: isExpanded ? null : '1/1',
                    cursor: 'pointer',
                    backgroundColor: isExpanded ? backgroundColor : theme.palette.common.white,
                    border: isExpanded ? '1px solid black' : '1px solid #CAC4D0',
                    borderRadius: '16px',
                    marginBottom: '16px',
                    overflow: 'hidden',
                }}
            >
                {isExpanded ? (
                    <Box p={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2} paddingBottom={2}>
                            <Box
                                sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    backgroundColor: tableStatus === "TO_VALIDATE" ? 'white' : '#65558F',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: tableStatus === "TO_VALIDATE" ? '#65558F' : 'white',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {table.name}
                            </Box>
                            <Typography variant='subtitle1' color={tableStatus === "TO_VALIDATE" ? 'white' : '#65558F'}>
                                {getStatusTranslation(tableStatus)}
                            </Typography>
                        </Box>
                        {order && (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%',
                                background: 'white',
                                border: '1px solid',
                                borderColor: theme.palette.outline.main,
                                borderRadius: '12px'
                            }} paddingX={3} paddingY={2}>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                                    <EditNote sx={{ height: '24px' }} /><Typography variant='subtitle3'> Bon de commande en cours:</Typography>
                                </Box>
                                <Box pt={2}>
                                    {order.order.foodItems.map((foodItem, index) => {
                                        return (
                                            <Box key={index} display="flex" flexDirection="column">
                                                <Typography component="p" variant="body-small">
                                                    {foodItem.quantity}x {getFoodItemById(foodItem.id).name}
                                                </Typography>
                                                {foodItem.additionalNote && (
                                                    <Typography component="p" variant="additionalNotes">
                                                        {foodItem.additionalNote}
                                                    </Typography>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        )}
                        {renderExpandedContent(tableStatus)}
                    </Box>
                ) : (
                    <div style={{ height: '100%' }} className={tableStatus === "TO_VALIDATE" ? 'heartbeat' : ''}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            backgroundColor={backgroundColor}
                            border="1px solid #CAC4D0"
                            borderRadius="16px"
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '36px',
                                    lineHeight: '140%',
                                    textAlign: 'center',
                                    color: tableStatus === "TO_VALIDATE" ? 'white' : '#000000',
                                }}
                            >
                                {table.name}
                            </Typography>
                        </Box>
                    </div>
                )}
            </motion.div>

            {/* Edit Table Dialog */}
            <Dialog open={isEditDialogOpen} onClose={handleCloseEditDialog}>
                <DialogTitle>Modifier le nom de la table</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Entrez un nouveau nom pour la table.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nom de la table"
                        fullWidth
                        variant="outlined"
                        value={newTableName}
                        onChange={(e) => setNewTableName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleEditTableName} color="primary">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Table Dialog */}
            <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Supprimer la table</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir supprimer cette table? Cette action est irréversible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteTable} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TableCard;
