import React from 'react';
import { Box, TextField } from '@mui/material';

const DescriptionField = ({ values, handleChange, handleBlur, touched, errors }) => (
    <Box sx={{ mb: 2, width: '100%' }}>
        <TextField
            name="description"
            label="Description"
            variant='outlined'
            multiline
            rows={2}
            fullWidth
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
            InputProps={{ textDecoration: 'none' }}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "black", // default border color
                    },
                },
            }}
        />
    </Box>
);

export default DescriptionField;
