import { useContext, useEffect, useState, useCallback } from 'react';
import { AppContext } from '../context/AppContext';

const useMenu = () => {
    const { menu, loading } = useContext(AppContext);

    const getFoodItemById = useCallback((foodItemId) => {
        for (const category in menu) {
            const item = menu[category].find(item => item.id === foodItemId);
            if (item) return item;
        }
        return null;
    }, [menu]);

    return { menu, loading, getFoodItemById };
};

export default useMenu;
