import axios from 'axios';
import { config } from './config';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';

const useApi = () => {
  const { eateryId } = useContext(AuthContext);

  // Create an instance of axios with the base URL and default headers
  const api = axios.create({
    baseURL: config.API_URL,
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  });

  // Utility functions to handle tokens
  const getAccessToken = () => localStorage.getItem('accessToken');
  const getRefreshToken = () => localStorage.getItem('refreshToken');
  const setAccessToken = (token) => localStorage.setItem('accessToken', token);
  const setRefreshToken = (token) => localStorage.setItem('refreshToken', token);
  const removeTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  // Function to refresh the access token using the refresh token
  const refreshToken = async () => {
    try {
      const refreshToken = getRefreshToken();
      const response = await axios.post(`${config.API_URL}/refresh-token`, null, {
        headers: {
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
      const { accessToken, refreshToken: newRefreshToken } = response.data;
      setAccessToken(accessToken);
      setRefreshToken(newRefreshToken);
      return accessToken;
    } catch (error) {
      console.error('Refresh token failed', error);
      removeTokens();
      window.location.href = '/login';
      throw error;
    }
  };

  // Function to make API calls with token handling
  const apiCall = async (options) => {
    try {
      const token = getAccessToken();
      if (token) {
        options.headers = {
          ...options.headers,
          'Authorization': `Bearer ${token}`,
        };
      }
      const response = await api(options);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newToken = await refreshToken();
        options.headers = {
          ...options.headers,
          'Authorization': `Bearer ${newToken}`,
        };
        const response = await api(options);
        return response.data;
      } else {
        throw error;
      }
    }
  };

  const getMenuApi = () => {
    return apiCall({
      url: `/api/eatery/${eateryId}/menu`,
      method: 'GET'
    });
  };

  // todo: check this, this looks wrong
  const setMenuApi = (category) => {
    return apiCall({
      url: `/admin/api/eatery/${eateryId}/menu/categories/${Object.keys(category)[0]}`,
      method: 'PUT',
      data: {
        id: Object.keys(category)[0],
        foodItems: category[Object.keys(category)[0]]
      },
    });
  };

  const createTableApi = (tableNumber) => {
    return apiCall({
      url: `/admin/api/tables/${eateryId}/${tableNumber}`,
      method: 'POST',
    });
  };

  const getTablesApi = () => {
    return apiCall({ url: `/api/tables/${eateryId}`, method: 'GET' });
  };

  const reorderTablesApi = (tableIds) => {
    return apiCall({
      url: `/admin/api/tables/${eateryId}/reorder`,
      method: 'PUT',
      data: JSON.stringify(tableIds),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  };

  const editTableNameApi = (tableId, tableName) => {
    return apiCall({ url: `/admin/api/tables/${tableId}`, method: 'PUT', data: { tableName } });
  };

  const deleteTableApi = (tableId) => {
    return apiCall({ url: `/admin/api/tables/${tableId}`, method: 'DELETE' });
  };

  const getOrdersApi = () => apiCall({ url: `/api/orders/${eateryId}`, method: 'GET' });

  const validateOrderApi = (eateryId, orderId) => {
    return apiCall({
      url: `/admin/api/orders/${eateryId}/${orderId}/validate`,
      method: 'POST',
    });
  };

  const openOrderApi = (eateryId, orderId) => {
    return apiCall({
      url: `/admin/api/orders/${eateryId}/${orderId}/open`,
      method: 'POST',
    });
  };

  const closeOrderApi = (eateryId, orderId) => {
    return apiCall({
      url: `/admin/api/orders/${eateryId}/${orderId}/close`,
      method: 'POST',
    });
  };

  const setSocialMediaApi = (socialNetworkName, link) => {
    return apiCall({
      url: `/api/${eateryId}/social-media`,
      method: 'POST',
      data: { socialNetworkName, link },
    });
  };

  const getCategoriesApi = () => {
    return apiCall({
      url: `/api/food/${eateryId}/categories`,
      method: 'GET'
    });
  };

  const reorderCategoriesApi = (categories) => {
    return apiCall({
      url: `/admin/api/food/${eateryId}/categories/reorder`,
      method: 'PUT',
      data: categories,
    });
  };

  const createCategoryApi = (categoryData) => {
    return apiCall({
      url: `/admin/api/food/${eateryId}/categories/create`,
      method: 'POST',
      data: categoryData,
    });
  };

  const updateCategoryApi = (categoryId, categoryData) => {
    return apiCall({
      url: `/admin/api/food/${eateryId}/categories/${categoryId}`,
      method: 'PUT',
      data: categoryData,
    });
  };

  const deleteCategoryApi = (categoryId) => {
    return apiCall({
      url: `/admin/api/food/${eateryId}/categories/${categoryId}`,
      method: 'DELETE',
    });
  };

  const createOrUpdateFoodItem = async (values, update, foodItemId, photo) => {
    const url = update ? `/admin/api/food/${eateryId}/update/${foodItemId}`
      : `/admin/api/food/${eateryId}/create`;

    const formData = new FormData();
    formData.append("foodItem", JSON.stringify(values));

    if (photo) {
      formData.append('photo', photo);
    }

    return apiCall({
      url,
      method: update ? 'PUT' : 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const deleteFoodItem = (foodItemData) => {
    return apiCall({
      url: `/admin/api/food/${eateryId}/${foodItemData}/delete`,
      method: 'DELETE',
    });
  };

  const deleteFoodItemPhoto = (foodItemId) => {
    return apiCall({
      url: `/admin/api/food/${eateryId}/${foodItemId}/photo/delete`,
      method: 'DELETE',
    });
  };

  const setOutOfStockApi = (foodItemId, bool) => {
    return apiCall({
      url: `/admin/api/food/${eateryId}/${foodItemId}?status=${bool}`,
      method: 'POST',
    });
  }

  const createOrderApi = (orderData) => {
    const url = orderData.tableId
      ? `/api/orders/${orderData.eateryId}/${orderData.tableId}/order${orderData.fingerprint ? "?fingerprint=" + orderData.fingerprint : ""}`
      : `/api/orders/${orderData.eateryId}/order${orderData.fingerprint ? "?fingerprint=" + orderData.fingerprint : ""}`;
    return apiCall({
      url,
      method: 'POST',
      data: orderData,
    });
  };

  const getCachedOrdersApi = () => {
    return apiCall({ url: `/admin/api/orders/cached/${eateryId}`, method: 'GET' });
  };

  const logout = () => {
    const response = apiCall({ url: '/api/auth/logout', method: 'POST' });

    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common['Authorization'];

    return response;
  };

  const updateOrderApi = (eateryId, tableIdOrOrderId, updatedOrder) => {
    return apiCall({
      url: `api/orders/${eateryId}/${tableIdOrOrderId}`,
      data: JSON.stringify(updatedOrder),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  const deleteOrderApi = (eateryId, tableIdOrOrderId) => {
    return apiCall({
      url: `/admin/api/orders/${eateryId}/${tableIdOrOrderId}`,
      method: 'DELETE',
    });
  };

  const clearOrdersApi = (eateryId) => {
    return apiCall({
      url: `/admin/api/orders/${eateryId}`,
      method: 'DELETE',
    });
  }

  const getRatings = (eateryId) => {
    return apiCall({
      url: `/admin/api/ratings/${eateryId}`,
      method: 'GET',
    })
  }

  return {
    apiCall,
    getMenuApi,
    setMenuApi,
    createTableApi,
    getTablesApi,
    reorderTablesApi,
    editTableNameApi,
    deleteTableApi,
    getOrdersApi,
    setSocialMediaApi,
    getCategoriesApi,
    reorderCategoriesApi,
    createCategoryApi,
    updateCategoryApi,
    deleteCategoryApi,
    createOrUpdateFoodItem,
    deleteFoodItem,
    deleteFoodItemPhoto,
    setOutOfStockApi,
    createOrderApi,
    getCachedOrdersApi,
    validateOrderApi,
    openOrderApi,
    closeOrderApi,
    updateOrderApi,
    deleteOrderApi,
    clearOrdersApi,
    getRatings,
    logout
  };
};

export default useApi;
