import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../config';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [eateryId, setEateryId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        const storedEateryId = localStorage.getItem('eateryId');

        if (accessToken && storedEateryId) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            // Fetch user details to check if the token is valid
            const fetchUserData = async () => {
                try {
                    const response = await axios.get(`${config.API_URL}/api/auth/user`);
                    setUser(response.data);
                    setEateryId(storedEateryId);
                    setIsAuthenticated(true);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        // Token might have expired, try to refresh it
                        const refreshToken = localStorage.getItem('refreshToken');
                        if (refreshToken) {
                            try {
                                await refreshAuthToken(refreshToken);
                                await fetchUserData(); // Retry fetching user data after refreshing token
                            } catch (refreshError) {
                                console.error('Refresh token failed:', refreshError);
                                logout();
                            }
                        } else {
                            logout();
                        }
                    } else {
                        console.error('Error fetching user data:', error);
                        logout();
                    }
                } finally {
                    setLoading(false);
                }
            };

            fetchUserData();
        } else {
            setIsAuthenticated(false);
            setLoading(false);
            navigate('/', { replace: true });
        }
    }, []);

    const refreshAuthToken = async (refreshToken) => {
        try {
            const response = await axios.post(`${config.API_URL}/api/auth/refresh-token`, {
                token: refreshToken,
            });
            const newAccessToken = response.data.accessToken;
            localStorage.setItem('accessToken', newAccessToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        } catch (error) {
            console.error('Error refreshing token:', error);
            throw error;
        }
    };

    const login = async (email, password) => {
        const url = `${config.API_URL}/api/auth/login`;
        const requestData = {
            login: email,
            password: password,
        };

        try {
            const response = await axios.post(url, requestData);
            const data = response.data;
            const accessToken = data.accessToken;
            const refreshToken = data.refreshToken;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('eateryId', data.eateryId);

            setUser({
                username: data.user,
                eateryId: data.eateryId,
                role: data.role
            });
            setEateryId(data.eateryId);
            setIsAuthenticated(true);
        } catch (error) {
            console.error('There was a problem with the login request:', error);
            throw new Error(error.response?.data?.message || 'Login failed');
        }
    };

    const logout = async () => {
        try {
            await axios.post(`${config.API_URL}/api/auth/logout`, null, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            console.error('There was a problem with the logout request:', error);
        } finally {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('eateryId');

            delete axios.defaults.headers.common['Authorization'];

            setUser(null);
            setIsAuthenticated(false);
            setEateryId(null);
            navigate('/', { replace: true });
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, isAuthenticated, loading, eateryId }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
