import { AddCircleOutlineOutlined, RemoveCircleOutlineRounded } from '@mui/icons-material';
import {
    Box,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { OrdersContext } from '../../../context/OrdersContext';
import LongButton from '../../../shared-components/LongButton';
import QuantitySelector from '../../../shared-components/QuantitySelector';
import theme from '../../../theme';

const TakeOrderFoodItemCard = ({ id, name, price, currency, otherSizes, requiredChoices, extras, initialQuantity, order, onUpdate }) => {
    const [expanded, setExpanded] = useState(false);
    const [quantity, setQuantity] = useState(initialQuantity);
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedRequiredChoices, setSelectedRequiredChoices] = useState({});
    const [selectedExtras, setSelectedExtras] = useState({});
    const [notes, setNotes] = useState('');
    const { addToOrder, createKey } = useContext(OrdersContext);

    const handleToggleExpand = () => {
        if ((!otherSizes || otherSizes.length === 0)
            && (!requiredChoices || requiredChoices.length === 0)
            && (!extras || Object.keys(extras).length === 0)) {
            handleAddToOrder();
        } else {
            setExpanded(!expanded);
        }
        //setExpanded(!expanded);
    };


    const handleOptionChange = (optionName, value) => {
        setSelectedRequiredChoices({ ...selectedRequiredChoices, [optionName]: value });
    };

    const handleExtraChange = (extra, value) => {
        setSelectedExtras({ ...selectedExtras, [extra.name]: extra.price });
    };

    const handleAddToOrder = async () => {
        const newItem = {
            id,
            name,
            quantity,
            selectedSize,
            selectedRequiredChoices,
            selectedExtras,
            additionalNote: notes,
            price,
            currency,
        };

        const r = await addToOrder(order?.order.id, newItem);
        onUpdate(r);
        setExpanded(false);
    };

    return (
        <Grid item sm={expanded ? 12 : 6} xs={12} key={id}>
            <Card sx={{
                padding: 2,
                marginBottom: 2,
                width: '100%',
                border: 1,
                borderRadius: 4,
                borderColor: theme.palette.outline.main
            }}>
                <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="labelMedium" fontWeight={700}>{name}</Typography>
                    <motion.div
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <IconButton onClick={handleToggleExpand} size='large'>
                            {expanded ? <RemoveCircleOutlineRounded sx={{ width: '32px', height: '32px' }} /> : <AddCircleOutlineOutlined sx={{ width: '32px', height: '32px' }} />}
                        </IconButton>
                    </motion.div>
                </Box>
                <AnimatePresence initial={false}>
                    {expanded && (
                        <motion.div
                            key="content"
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <Box sx={{ marginTop: 2 }}>
                                {otherSizes && otherSizes.length > 0 && (
                                    <Box sx={{ marginTop: 2 }}>
                                        <Typography variant="subtitle2" fontWeight={900} mb={1}>Autres tailles</Typography>
                                        {otherSizes.map((size, index) => (
                                            <FormControlLabel
                                                key={size.name}
                                                control={
                                                    <Checkbox
                                                        checked={selectedSize === size.name}
                                                        onChange={() => setSelectedSize((selectedSize === size.name) ? null : size.name)} // Ensures only one is selected
                                                    />
                                                }
                                                label={`${size.name}` + (size.price > 0 ? ` (+${size.price} ${currency})` : '')}
                                            />
                                        ))}
                                    </Box>
                                )}
                                {requiredChoices && requiredChoices.length > 0 && (
                                    <Box sx={{ marginTop: 2 }}>
                                        <Typography variant="labelSmall" fontWeight={900} mb={1}>Choix obligatoires</Typography>
                                        {requiredChoices.map((choice) => (
                                            <Box key={choice.name} sx={{ marginY: 2 }}>
                                                <Typography variant="subtitle2" fontWeight={700}>{choice.name}</Typography>
                                                <RadioGroup
                                                    value={selectedRequiredChoices[choice.name] || ''}
                                                    onChange={(e) => handleOptionChange(choice.name, e.target.value)}
                                                >
                                                    {choice.options.map((option) => (
                                                        <FormControlLabel
                                                            key={option.choice}
                                                            value={option.choice}
                                                            control={<Radio />}
                                                            label={`${option.choice} (+${option.price}${currency})`}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {extras && extras.length > 0 && (
                                    <Box sx={{ marginTop: 2 }}>
                                        <Typography variant="labelSmall" fontWeight={900} mb={1}>Extras</Typography>
                                        {extras.map((extra) => {
                                            return (
                                                <Box key={extra.name} sx={{ marginBottom: 1 }}>
                                                    <FormControlLabel
                                                        key={extra}
                                                        control={<Checkbox
                                                            checked={!!selectedExtras[extra.name]}
                                                            onChange={(e) => handleExtraChange(extra, e.target.value)}
                                                        />}
                                                        label={`${extra.name} (+${extra.price}${currency})`}
                                                    />
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                )}
                                <TextField
                                    label="Note additionnelle"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2, gap: 2 }}>
                                    <QuantitySelector initialQuantity={quantity} onChange={setQuantity} />
                                    <LongButton variant="primary" onClick={handleAddToOrder} label={"Ajouter"} />
                                </Box>
                            </Box>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Card>
        </Grid>
    );
};

export default TakeOrderFoodItemCard;
