import React from 'react';
import { Grid, Avatar, Card, Typography, Box } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';
import theme from '../../../theme';
import LongButton from '../../../shared-components/LongButton';

const FoodItemCard = ({
    id,
    category,
    isSelected,
    name,
    description,
    order,
    price,
    otherSizes,
    requiredChoices,
    currency,
    photo,
    video,
    extras,
    newItem,
    popular,
    outOfStock,
    tags,
    pullUp,
    pushDown,
}) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`item/${id}`, {
            state: {
                id,
                name,
                description,
                order,
                price,
                otherSizes,
                requiredChoices,
                currency,
                photo,
                video,
                extras,
                newItem,
                popular,
                outOfStock,
                tags,
                category,
            },
        });
    };

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '16px',
                height: 'auto',
                backgroundColor: outOfStock ? theme.palette.container.primary.highest : theme.palette.secondaryContainer.main,
                borderRadius: '12px',
                border: '1px solid ' + theme.palette.outline.main,
                marginBottom: '16px',
                paddingX: 3,
                paddingY: 2,
                cursor: 'pointer'
            }}
            onClick={handleCardClick}
        >
            <Grid container alignItems="center">
                <Grid
                    item
                    xs={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                        height: '100%',
                    }}
                >
                    <Avatar
                        sx={{
                            width: '30px',
                            height: '30px',
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            marginBottom: '8px',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            pullUp(e);
                        }}
                    >
                        <ArrowUpward sx={{ color: theme.palette.primary.contrastText }} />
                    </Avatar>
                    <Avatar
                        sx={{
                            width: '30px',
                            height: '30px',
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            pushDown(e);
                        }}
                    >
                        <ArrowDownward sx={{ color: theme.palette.primary.contrastText }} />
                    </Avatar>
                </Grid>
                <Grid item xs={photo ? 7 : 10} pr={2}>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '1rem',
                                    color: theme.palette.text.primary,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                {name}
                            </Typography>
                        </Grid>
                        {
                            description
                                ? <Grid item>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '0.875rem',
                                            color: theme.palette.text.secondary,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'vertical',
                                            paddingY: 1
                                        }}
                                    >
                                        {description}
                                    </Typography>
                                </Grid>
                                : null
                        }
                        <Grid item>
                            <Typography
                                variant="body"
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '1rem',
                                    color: theme.palette.text.secondary,
                                    paddingBottom: 1,
                                }}
                                textAlign='left'
                            >
                                {price}&nbsp;{currency || ''}
                            </Typography>
                            {popular && <Typography variant="body2" color="primary" textAlign='left'>•&nbsp;Populaire&nbsp;⭐</Typography>}
                            {outOfStock && <Typography variant="body2" color="primary" textAlign='left'>•&nbsp;Indisponible&nbsp;❌&nbsp;</Typography>}
                            {newItem && <Typography variant="body2" color="primary" textAlign='left'>•&nbsp;Nouveau&nbsp;🔥&nbsp;</Typography>}
                        </Grid>
                    </Grid>
                    <Grid item mt={2}>
                        <LongButton onClick={handleCardClick} variant={"outlined"} label={"Editer"} sx={{ backgroundColor: 'transparent', borderRadius: '8px', height: "40px" }} />
                    </Grid>
                </Grid>
                {photo && (
                    <Grid item xs={3}>
                        <picture>
                            {photo?.thumbnail?.webp && <source srcSet={photo.thumbnail.webp} type="image/webp" />}
                            {photo?.thumbnail?.avif && <source srcSet={photo.thumbnail.avif} type="image/avif" />}
                            {photo?.thumbnail?.jpg && (
                                <Box
                                    component="img"
                                    src={photo.thumbnail.jpg}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '16px',
                                        border: '1px solid ' + theme.palette.outline.main,
                                    }}
                                    alt={name}
                                />
                            )}
                        </picture>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default React.memo(FoodItemCard);
