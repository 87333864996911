import React, { useContext } from 'react';
import { Drawer, Box, ListItem, ListItemText, IconButton } from '@mui/material';
import { Inventory2, Star } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { default as MenuIcon } from '@mui/icons-material/MenuBookOutlined';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { useTheme } from '@emotion/react';
import LongButton from '../../../shared-components/LongButton';

const SidePanel = ({ open, onClose }) => {
    const { user, logout } = useContext(AuthContext);
    const theme = useTheme();

    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <Box
                gap={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    //width: 250,
                    height: '100%',
                    paddingX: '16px',
                    paddingY: 3,
                    background: theme.palette.surface.container,
                    border: `1px solid ${theme.palette.primary.main}`,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: theme.palette.text.primary }} />
                    </IconButton>
                </Box>
                {/* Menu list */}
                {/* {user.role === "ADMIN" && ( */}
                {/* <> */}
                <ListItem>
                    <MenuIcon sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                    <Link to="orders/archived" style={{ textDecoration: 'none' }}>
                        <ListItemText
                            primary="Voir mon menu"
                            sx={{
                                color: theme.palette.text.secondary,
                            }}
                        />
                    </Link>
                </ListItem>

                <ListItem>
                    <ArchiveIcon sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                    <Link to="orders/archived" style={{ textDecoration: 'none' }}>
                        <ListItemText
                            primary="Voir l'historique de mes commandes"
                            sx={{
                                fontSize: '5px',
                                fontWeight: 600,
                                color: theme.palette.text.secondary,
                            }}
                        />
                    </Link>
                </ListItem>

                <ListItem>
                    <Star sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                    <Link to="ratings" style={{ textDecoration: 'none' }}>
                        <ListItemText
                            primary="Voir les avis"
                            sx={{
                                fontSize: '5px',
                                fontWeight: 600,
                                color: theme.palette.text.secondary,
                            }}
                        />
                    </Link>
                </ListItem>

                <ListItem>
                    <Inventory2 sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                    <Link to="out-of-stock" style={{ textDecoration: 'none' }}>
                        <ListItemText
                            primary="Produits indisponibles"
                            primaryTypographyProps={{
                                fontFamily: 'Nunito Sans, sans-serif',
                                fontSize: '15px',
                            }}
                            sx={{
                                fontWeight: 600,
                                color: theme.palette.text.secondary,
                            }}
                        />
                    </Link>
                </ListItem>
                {/* </> */}
                {/* )} */}
                {/* Logout button */}
                <Box mt={3} padding={2} width="100%">
                    <LongButton variant="outlined" onClick={logout} label={`Se déconnecter (${user.username})`} />
                </Box>
            </Box>
        </Drawer>
    );
};

export default SidePanel;
